export const SUBSCRIPTION_SORT_OPTIONS = [
  {
    id: "1",
    label: "Created At - Ascending",
    value: { sortBy: "created_at", sortDir: "asc" },
  },
  {
    id: "2",
    label: "Created At - Descending",
    value: { sortBy: "created_at", sortDir: "desc" },
  },
];

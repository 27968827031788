import { useState } from "react";
import { NavLink } from "react-router-dom";
import { ReactComponent as Icon } from "../../assets/icons/logo.svg";
import { useAppSelector } from "../../hooks/reduxHooks";
import { ROUTES_PATHS } from "../../common/constants";
import OutsideClick from "../hoc/OutsideClick";
import { NavRoutes, ProfileMenu } from "./components";
import { LogoContainer, NavProfileButton, TopNav } from "./StyledComponents";

export const TopNavigation = () => {
  const { user } = useAppSelector((state) => state.auth);
  const [profileDropDownOpen, setProfileDropDownOpen] = useState(false);

  const openDropDown = () => {
    setProfileDropDownOpen(!profileDropDownOpen);
  };

  return (
    <TopNav>
      <LogoContainer>
        <NavLink to={ROUTES_PATHS.Dashboard.home}>
          <Icon height={45} />
        </NavLink>
      </LogoContainer>

      <NavRoutes />

      <NavProfileButton onclick={openDropDown} user={user} />
      {profileDropDownOpen ? (
        <OutsideClick callback={openDropDown}>
          <ProfileMenu callback={openDropDown} user={user} />
        </OutsideClick>
      ) : null}
    </TopNav>
  );
};

import axiosInstance from "../../config/axios";
import ServerRoutes from "./routes";
import { getParamsUrl, handleAxiosError } from "./utils/common";

const getUserBreathingCycle = async (params: {
  userId: string;
  limit: number;
  page?: number;
  skip?: number;
}) => {
  try {
    const url = getParamsUrl(
      ServerRoutes.breathingCycles.getUserBreathingCycle,
      params
    );
    const { data } = await axiosInstance.get(url);
    return data;
  } catch (error: any) {
    const err = handleAxiosError(error);
    console.error(err.data);
    return [];
  }
};

const getUserBreathingCycleStreak = async (params: { userId: string }) => {
  try {
    const url = getParamsUrl(
      ServerRoutes.breathingCycles.getUserRangeBreathingCycle,
      params
    );
    const { data } = await axiosInstance.get(url);
    return data;
  } catch (error: any) {
    const err = handleAxiosError(error);
    console.error(err.data);
    return [];
  }
};

export const breathingCyclesApi = {
  getUserBreathingCycle,
  getUserBreathingCycleStreak,
};

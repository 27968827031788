import { PayloadAction } from "@reduxjs/toolkit";
import { call, put, takeLatest } from "redux-saga/effects";
import { alertError } from "../notifications-manager/notification-manager";
import {
  createLevelFailed,
  createLevelStart,
  createLevelSuccess,
  getLevelFailed,
  getLevelStart,
  getLevelSuccess,
  updateLevelFailed,
  updateLevelStart,
  updateLevelSuccess,
} from "./edit-level-slice";
import {
  ICreateLevelBody,
  IGetLevelParams,
  IUpdateLevelBody,
} from "../../../interfaces/api/levels";
import levelsApi from "../../api/levels-api";

function* createLevel(action: PayloadAction<ICreateLevelBody>): any {
  try {
    const levelData = action.payload;
    const { data, error } = yield call(levelsApi.createLevel, levelData);
    if (error) {
      yield put(createLevelFailed(error));
      return;
    }
    yield put(createLevelSuccess(data));
  } catch (error: any) {
    yield put(createLevelFailed(error));
    yield put(alertError(error.message));
  }
}

function* updateLevel(action: PayloadAction<IUpdateLevelBody>): any {
  try {
    const params = action.payload;

    const { data, error } = yield call(levelsApi.updateLevel, params);
    if (error) {
      yield put(updateLevelFailed(error));
      return;
    }
    yield put(updateLevelSuccess(data));
  } catch (error: any) {
    yield put(updateLevelFailed(error));
    yield put(alertError(error.message));
  }
}

function* getLevel(action: PayloadAction<IGetLevelParams>): any {
  try {
    const { levelRewardId } = action.payload;
    const { data, error } = yield call(levelsApi.getLevel, { levelRewardId });
    if (error) {
      throw error;
    }
    yield put(getLevelSuccess(data));
  } catch (error: any) {
    yield put(getLevelFailed(error));
  }
}

function* editLevelSaga() {
  yield takeLatest(updateLevelStart.type, updateLevel);
  yield takeLatest(createLevelStart.type, createLevel);
  yield takeLatest(getLevelStart.type, getLevel);
}

export default editLevelSaga;

import { ROUTES_PATHS } from "../../../../common";
import FormInput from "../../../ui/inputs/FormInput";
import { FullAccount, ISubscription } from "../../../../interfaces/models";
import { useNavigate } from "react-router-dom";
import { ClickableDiv } from "./SubscriptionCodeNav.style";
import { Link } from "@mui/icons-material";
import { useAsync } from "../../../../hooks/useAsync";
import { subscriptionApi } from "../../../../store/api";

export const SubscriptionCodeNav = ({
  value,
}: {
  value: any;
  row: FullAccount;
}) => {
  const { value: subscription } = useAsync<ISubscription>(
    () => subscriptionApi.getSubscriptionByCode({ code: value }),
    [value]
  );
  const navigate = useNavigate();

  return (
    <ClickableDiv
      exist={!!subscription}
      onClick={() =>
        subscription &&
        navigate(
          ROUTES_PATHS.Subscription.subscriptionPage.replace(
            ":id",
            subscription._id
          )
        )
      }
    >
      <FormInput
        onClick={() =>
          subscription &&
          navigate(
            ROUTES_PATHS.Subscription.subscriptionPage.replace(
              ":id",
              subscription._id
            )
          )
        }
        value={value || ""}
        contentAfter={subscription ? <Link /> : undefined}
        type="url"
        style={{
          marginTop: "5px",
          pointerEvents: "none",
          width: "100%",
          color: "blue",
        }}
      />
    </ClickableDiv>
  );
};

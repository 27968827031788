import { PayloadAction } from "@reduxjs/toolkit";
import { call, put, takeLatest } from "redux-saga/effects";
import { alertError } from "../notifications-manager/notification-manager";
import {
  createSceneFailed,
  createSceneStart,
  createSceneSuccess,
  fetchSceneItemsFailed,
  fetchSceneItemsStart,
  fetchSceneItemsSuccess,
  getSceneFailed,
  getSceneStart,
  getSceneSuccess,
  publishSceneFailed,
  publishSceneStart,
  publishSceneSuccess,
  updateSceneFailed,
  updateSceneStart,
  updateSceneSuccess,
} from "./edit-scene-slice";
import {
  ICreateSceneBody,
  IGetSceneItemsParams,
  IGetSceneParams,
  IUpdateSceneBody,
} from "../../../interfaces/api/scenes";
import scenesApi from "../../api/scenes-api";

function* createScene(action: PayloadAction<ICreateSceneBody>): any {
  try {
    const sceneData = action.payload;
    const { data, error } = yield call(scenesApi.createScene, sceneData);
    if (error) {
      yield put(createSceneFailed(error));
      return;
    }
    yield put(createSceneSuccess(data));
  } catch (error: any) {
    yield put(createSceneFailed(error));
    yield put(alertError(error.message));
  }
}

function* updateScene(action: PayloadAction<IUpdateSceneBody>): any {
  try {
    const params = action.payload;
    const { data, error } = yield call(scenesApi.updateScene, params);
    if (error) {
      yield put(updateSceneFailed(error));
      return;
    }
    yield put(updateSceneSuccess(data));
  } catch (error: any) {
    yield put(updateSceneFailed(error));
    yield put(alertError(error.message));
  }
}

function* getScene(action: PayloadAction<IGetSceneParams>): any {
  try {
    const { sceneId } = action.payload;
    const { data, error } = yield call(scenesApi.getScene, { sceneId });
    if (error) {
      throw error;
    }
    yield put(getSceneSuccess(data));
  } catch (error: any) {
    yield put(getSceneFailed(error));
  }
}

function* getSceneItems(
  action: PayloadAction<{ params: IGetSceneItemsParams }>
): any {
  try {
    const { params } = action.payload;
    const { data, error } = yield call(scenesApi.getSceneItems, params);
    if (error) {
      throw error;
    }
    yield put(fetchSceneItemsSuccess(data));
  } catch (error: any) {
    yield put(fetchSceneItemsFailed(error));
  }
}

function* publishScene(action: PayloadAction<IGetSceneParams>): any {
  try {
    const { sceneId } = action.payload;
    const { data, error } = yield call(scenesApi.publishScene, { sceneId });
    if (error) {
      throw error;
    }
    yield put(publishSceneSuccess(data));
    yield put(getSceneStart({ sceneId }));
  } catch (error: any) {
    yield put(publishSceneFailed(error));
    yield put(alertError(error.message));
  }
}

function* editSceneSaga() {
  yield takeLatest(updateSceneStart.type, updateScene);
  yield takeLatest(createSceneStart.type, createScene);
  yield takeLatest(getSceneStart.type, getScene);
  yield takeLatest(fetchSceneItemsStart.type, getSceneItems);
  yield takeLatest(publishSceneStart.type, publishScene);
}

export default editSceneSaga;

import { useState, useEffect, useMemo } from "react";
import { Input, Subtitle1, useId, Image } from "@fluentui/react-components";
import { FlexRowWrapped } from "../common/containers";
import { debounce } from "lodash";
import { FlexColumn } from "../common/containers";
import LoaderContainer from "../ui/loaders/LoaderContainer";
import Pagination from "../ui/Pagination";
import { LibraryImage } from "../../interfaces/models/library-img";
import {
  GridLibraryImgContainer,
  GridLibraryImgName,
  GridLibraryWrapped,
} from "./styledComponents";

interface IGridLibraryProps {
  list: LibraryImage[];
  limit: number;
  getItems: Function;
  total: number;
  page?: number;
  loading: boolean;
  chosenImage: LibraryImage | null;
  setChosenImage: Function;
}

const getNumOfPages = (total: number, limit: number) => {
  try {
    if (!limit) return 0;
    return Math.ceil(total / limit);
  } catch (err) {
    return 0;
  }
};

const LibraryGrid = ({
  list,
  limit,
  getItems,
  total,
  page,
  loading,
  chosenImage,
  setChosenImage,
}: IGridLibraryProps) => {
  const [search, setSearch] = useState<string>("");
  const searchId = useId("input-search");

  useEffect(() => {
    if (search.length === 0 || search.length > 2) debouncedGetItems(search);
  }, [search]);

  const debouncedGetItems = useMemo(
    () =>
      debounce((query: string) => {
        getItems(1, query);
      }, 300),
    [getItems]
  );

  return (
    <FlexColumn style={{ marginBottom: "30px" }}>
      <Input
        value={search || ""}
        onChange={(e) => {
          setSearch(e.currentTarget.value);
        }}
        type="search"
        placeholder="Search"
        appearance="underline"
        id={searchId}
        style={{
          width: "400px",
          alignSelf: "center",
          margin: "10px 10px 40px",
        }}
      />

      <FlexRowWrapped style={{ justifyContent: "center" }}>
        {loading ? <LoaderContainer /> : null}
        {total === 0 ? (
          <Subtitle1 align="center">no results</Subtitle1>
        ) : (
          <GridLibraryWrapped>
            {list.map((item: LibraryImage, index) => {
              return (
                <GridLibraryImgContainer
                  key={index}
                  isChosen={chosenImage?._id === item._id}
                  onClick={() => setChosenImage(item)}
                >
                  <Image style={{ maxHeight: "100%" }} src={item.mediaLink} />
                  <GridLibraryImgName>{item.name}</GridLibraryImgName>
                </GridLibraryImgContainer>
              );
            })}
            {getNumOfPages(total, limit) > 1 ? (
              <Pagination
                onPageChange={(page: number) => {
                  getItems(page);
                }}
                pageIndex={page || 1}
                pageCount={getNumOfPages(total, limit)}
                totalPageButtons={limit}
              />
            ) : null}
          </GridLibraryWrapped>
        )}
      </FlexRowWrapped>
    </FlexColumn>
  );
};

export default LibraryGrid;

//Forms
import {
  UploadBoxFormRules,
  UpdateBoxFormRules,
  UpdateLevelFormRules,
  UpdateSceneFormRules,
  UploadLevelFormRules,
  UploadSceneFormRules,
  UploadFirmwareFormRules,
  UpdateBoxPrizeFormRules,
  UploadBoxPrizeFormRules,
  UpdateSceneItemFormRules,
  UploadSceneItemFormRules,
  UpdateGameConfigFormRules,
  UpdateDailyRewardFormRules,
  UploadDailyRewardFormRules,
  UpdateLibraryImageFormRules,
  UploadLibraryImageFormRules,
} from "../../components/navigation/FormsDefinitions";

//Components
import Fota from "../../components/fota/Fota";
import Boxes from "../../components/boxes/Boxes";
import Levels from "../../components/levels/Levels";
import Scenes from "../../components/scenes/Scenes";
import BoxPage from "../../components/boxes/BoxPage";
import ScenePage from "../../components/scenes/ScenePage";
import LevelPage from "../../components/levels/LevelPage";
import Library from "../../components/img-library/Library";
import PrizePage from "../../components/boxes/prizes/PrizePage";
import GameConfig from "../../components/game-config/GameConfig";
import SceneItemPage from "../../components/scenes/items/ItemPage";
import { Accounts, AccountPage } from "../../components/accounts";
import DailyRewards from "../../components/daily-rewards/DailyRewards";
import DailyRewardPage from "../../components/daily-rewards/DailyRewardPage";
import LibraryImagePage from "../../components/img-library/LibraryImagePage";
import { Subscription, SubscriptionPage } from "../../components/subscription";

//Common
import { ROUTES_PATHS } from "../../common";

//Interfaces
import { Route } from "../../routes/interfaces";

export const ROUTES: Route[] = [
  {
    path: ROUTES_PATHS.Dashboard.accounts,
    mainComponent: Accounts,
    adminOnly: false,
  },
  {
    path: ROUTES_PATHS.Account.accountPage,
    mainComponent: AccountPage,
    adminOnly: false,
  },
  {
    path: ROUTES_PATHS.Dashboard.subscription,
    mainComponent: Subscription,
    adminOnly: false,
  },
  {
    path: ROUTES_PATHS.Subscription.subscriptionPage,
    mainComponent: SubscriptionPage,
    adminOnly: false,
  },
  {
    path: ROUTES_PATHS.Dashboard.library,
    mainComponent: Library,
    adminOnly: true,
  },
  {
    path: ROUTES_PATHS.Library.imagePage,
    mainComponent: LibraryImagePage,
    metadata: {
      editForm: UpdateLibraryImageFormRules,
      createForm: UploadLibraryImageFormRules,
    },
    adminOnly: true,
  },
  {
    path: ROUTES_PATHS.Dashboard.scenes,
    mainComponent: Scenes,
    adminOnly: true,
  },
  {
    path: ROUTES_PATHS.Scene.scenePage,
    mainComponent: ScenePage,
    metadata: {
      editForm: UpdateSceneFormRules,
      createForm: UploadSceneFormRules,
    },
    adminOnly: true,
  },
  {
    path: ROUTES_PATHS.Scene.sceneItemPage,
    mainComponent: SceneItemPage,
    metadata: {
      editForm: UpdateSceneItemFormRules,
      createForm: UploadSceneItemFormRules,
    },
    adminOnly: true,
  },
  { path: ROUTES_PATHS.Dashboard.boxes, mainComponent: Boxes, adminOnly: true },
  {
    path: ROUTES_PATHS.Box.boxPage,
    mainComponent: BoxPage,
    metadata: { editForm: UpdateBoxFormRules, createForm: UploadBoxFormRules },
    adminOnly: true,
  },
  {
    path: ROUTES_PATHS.Box.boxPrizePage,
    mainComponent: PrizePage,
    metadata: {
      editForm: UpdateBoxPrizeFormRules,
      createForm: UploadBoxPrizeFormRules,
    },
    adminOnly: true,
  },
  {
    path: ROUTES_PATHS.Dashboard.levels,
    mainComponent: Levels,
    adminOnly: true,
  },
  {
    path: ROUTES_PATHS.Level.levelPage,
    mainComponent: LevelPage,
    metadata: {
      editForm: UpdateLevelFormRules,
      createForm: UploadLevelFormRules,
    },
    adminOnly: true,
  },
  {
    path: ROUTES_PATHS.Dashboard.dailyRewards,
    mainComponent: DailyRewards,
    adminOnly: true,
  },
  {
    path: ROUTES_PATHS.DailyReward.dailyRewardPage,
    mainComponent: DailyRewardPage,
    metadata: {
      editForm: UpdateDailyRewardFormRules,
      createForm: UploadDailyRewardFormRules,
    },
    adminOnly: true,
  },
  {
    path: ROUTES_PATHS.Dashboard.gameConfig,
    mainComponent: GameConfig,
    metadata: { editForm: UpdateGameConfigFormRules },
    adminOnly: true,
  },
  {
    path: ROUTES_PATHS.Dashboard.fota,
    mainComponent: Fota,
    metadata: { editForm: UploadFirmwareFormRules },
    adminOnly: true,
  },
];

import { FullAccount } from "../../../interfaces/models";
import { accessByString } from "../../../utils";
import { Leaves } from "../../../types";
import { Field } from "../interface";

export const getDetails = (details: Field<FullAccount>[][], row: any) => {
  return details.map((detailsKeys) =>
    detailsKeys.reduce(
      (prev, { label, key, required, editable, Components }) => [
        ...prev,
        {
          label,
          value: accessByString(key, row),
          required,
          editable,
          key: key,
          Components,
        },
      ],
      [] as {
        label: string;
        value: any;
        required?: boolean;
        editable?: boolean;
        key: Leaves<FullAccount>;
        Components?: (props: {
          value: string;
          row: FullAccount;
        }) => JSX.Element;
      }[]
    )
  );
};

import React, { useEffect } from "react";
import {
  Button,
  LargeTitle,
  Subtitle2,
  Label,
  tokens,
  Divider,
  Title2,
  Title3,
} from "@fluentui/react-components";
import LoaderContainer from "../ui/loaders/LoaderContainer";
import { useForm } from "../../hooks/useForm";
import { useButtonStyles } from "../../hooks/useStyles";
import { useAppDispatch, useAppSelector } from "../../hooks/reduxHooks";
import {
  FormContainer,
  FormInputContainer,
  TitleContainer,
  FlexColumn,
  FlexRowSpaced,
  FlexRowWrapped,
  FlexRow,
} from "../common/containers";
import {
  getGameConfigStart,
  updateGameConfigStart,
} from "../../store/features/game-config/game-config-slice";
import { IUpdateGameConfigBody } from "../../interfaces/api/game-config";
import { GameConfig } from "../../interfaces/models/game-config";
import {
  getInitialFormValues,
  getValidationsObj,
} from "../../utils/forms-utils";
import FormInput from "../ui/inputs/FormInput";
import SwitchInput from "../common/Switch";
import usePermission from "../../hooks/usePermission";

const GameConfigPage = (props: { metadata: any }) => {
  const currGameConfig = useAppSelector((state) => state.gameConfig.gameConfig);
  const { loading } = useAppSelector((state) => state.gameConfig);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getGameConfigStart());
  }, []);

  return (
    <FlexColumn style={{ padding: " 0 3rem" }}>
      {loading ? <LoaderContainer /> : null}
      <FlexRowSpaced>
        <TitleContainer>
          <LargeTitle>Settings </LargeTitle>
        </TitleContainer>
      </FlexRowSpaced>
      {currGameConfig ? (
        <GameConfigForm
          gameConfig={currGameConfig}
          metadata={props.metadata.editForm}
        />
      ) : null}
    </FlexColumn>
  );
};

const GameConfigForm = (props: {
  gameConfig: GameConfig | null;
  metadata: any;
}) => {
  const { gameConfig, metadata } = props;

  const loading = useAppSelector((state) => state.gameConfig.loading);
  const dispatch = useAppDispatch();
  const buttonClasses = useButtonStyles();
  const adminAndStronger = usePermission("admin:>=");

  const onEditSubmit = (data: GameConfig) => {
    const params: IUpdateGameConfigBody = {
      tutorialConfig: {
        conditionsList: data.tutorialConfig.conditionsList,
        amountOfCoinsReceivedFromBox:
          data.tutorialConfig.amountOfCoinsReceivedFromBox,
        breathingCyclesNeeded: data.tutorialConfig.breathingCyclesNeeded,
        numberOfLongExhalations: data.tutorialConfig.numberOfLongExhalations,
        numberOfMinimalExhalations:
          data.tutorialConfig.numberOfMinimalExhalations,
        numberOfLightsInMinExhalations:
          data.tutorialConfig.numberOfLightsInMinExhalations,
        guidedSessionTimeout: data.tutorialConfig.guidedSessionTimeout,
      },
      devicesConfig: {
        timeForTimer: data.devicesConfig.timeForTimer,
        qualifiedMinTime: data.devicesConfig.qualifiedMinTime,
        qualifiedMinBreathingCycles:
          data.devicesConfig.qualifiedMinBreathingCycles,
        deviceSearchInterval: data.devicesConfig.deviceSearchInterval,
        unlockDuration: data.devicesConfig.unlockDuration,
      },
      devicesNotificationsConfig: {
        _id: data.devicesNotificationsConfig._id,
        notification1: {
          text: data.devicesNotificationsConfig.notification1.text,
          daysBeforeLock:
            data.devicesNotificationsConfig.notification1.daysBeforeLock,
          active: data.devicesNotificationsConfig.notification1.active,
        },
        notification2: {
          text: data.devicesNotificationsConfig.notification2.text,
          daysBeforeLock:
            data.devicesNotificationsConfig.notification2.daysBeforeLock,
          active: data.devicesNotificationsConfig.notification2.active,
        },
        notification3: {
          text: data.devicesNotificationsConfig.notification3.text,
          daysBeforeLock:
            data.devicesNotificationsConfig.notification3.daysBeforeLock,
          active: data.devicesNotificationsConfig.notification3.active,
        },
        notification4: {
          text: data.devicesNotificationsConfig.notification4.text,
          daysBeforeLock:
            data.devicesNotificationsConfig.notification4.daysBeforeLock,
          active: data.devicesNotificationsConfig.notification4.active,
        },
        notification5: {
          text: data.devicesNotificationsConfig.notification5.text,
          daysBeforeLock:
            data.devicesNotificationsConfig.notification5.daysBeforeLock,
          active: data.devicesNotificationsConfig.notification5.active,
        },
      },
      keysPerDeviceUse: data.keysPerDeviceUse,
      keySlotsDefault: data.keySlotsDefault,
    };
    dispatch(updateGameConfigStart(params));
  };

  const {
    handleSubmit,
    handleChange,
    handleInnerChange,
    handleInnerNotificationChange,
    data: editGameConfig,
    errors,
  } = useForm<any>({
    initialValues: getInitialFormValues(gameConfig),
    validations: getValidationsObj(metadata),
    onSubmit: () => {
      if (onEditSubmit) {
        onEditSubmit(editGameConfig);
      }
    },
  });

  const onEditClick = () => {
    handleSubmit();
  };

  return (
    <FlexColumn>
      <FormContainer style={{ opacity: loading ? "0.3" : "1" }}>
        {metadata != null ? (
          <>
            <FlexRow>
              <FlexRowWrapped style={{ gap: "40px" }}>
                {Object.keys(metadata).map((key, index) => {
                  return (
                    <section key={index} style={{ width: "100%" }}>
                      {metadata[key].type === "divider" ? (
                        <>
                          <Divider style={{ marginBottom: "30px" }} />
                          <Title2>{metadata[key].fieldName} </Title2>
                        </>
                      ) : key === "tutorialConfig" ||
                        key === "devicesConfig" ? (
                        <FlexRowWrapped style={{ gap: "40px" }}>
                          {" "}
                          {Object.keys(metadata[key]).map((innerKey, index) => {
                            return (
                              <FormInputContainer key={index}>
                                <FlexRow style={{ position: "relative" }}>
                                  <Label>
                                    {metadata[key][innerKey].fieldName}:
                                    {metadata[key][innerKey].required ? (
                                      <>
                                        &nbsp;{" "}
                                        <Subtitle2
                                          style={{
                                            color:
                                              tokens.colorPaletteCranberryBorderActive,
                                          }}
                                        >
                                          *
                                        </Subtitle2>{" "}
                                      </>
                                    ) : null}
                                  </Label>
                                </FlexRow>
                                {metadata[key][innerKey].type === "boolean" ? (
                                  <SwitchInput
                                    permission={"admin:>="}
                                    checked={editGameConfig[key][innerKey]}
                                    setChecked={handleInnerChange}
                                    onChange={(ev: any) => {
                                      handleInnerChange(
                                        key,
                                        innerKey,
                                        ev.currentTarget.checked
                                      );
                                    }}
                                    label={""}
                                  />
                                ) : (
                                  <FormInput
                                    permission={"admin:>="}
                                    value={editGameConfig[key][innerKey] || ""}
                                    style={{
                                      marginTop: "5px",
                                      pointerEvents: !metadata[key][innerKey]
                                        .editable
                                        ? "none"
                                        : "unset",
                                    }}
                                    placeholder={
                                      metadata[key][innerKey].fieldName
                                    }
                                    onChange={(ev) =>
                                      handleInnerChange(
                                        key,
                                        innerKey,
                                        ev.target.value
                                      )
                                    }
                                    errorMessage={errors[key]}
                                    type={
                                      metadata[key][innerKey].type || "string"
                                    }
                                  />
                                )}
                              </FormInputContainer>
                            );
                          })}
                        </FlexRowWrapped>
                      ) : key === "devicesNotificationsConfig" ? (
                        <FlexRowWrapped style={{ gap: "50px" }}>
                          {Object.keys(metadata[key]).map((innerKey, index) => {
                            return (
                              <FlexColumn key={index}>
                                <TitleContainer>
                                  <Title3>
                                    {metadata[key][innerKey].fieldName}{" "}
                                  </Title3>
                                </TitleContainer>
                                {Object.keys(metadata[key][innerKey]).map(
                                  (notificationKey, index1) => {
                                    return (
                                      <Notifications
                                        permission={"admin:>="}
                                        key={notificationKey + index}
                                        outerKey={key}
                                        notificationKey={notificationKey}
                                        index={index1}
                                        metadata={metadata}
                                        innerKey={innerKey}
                                        editGameConfig={editGameConfig}
                                        handleInnerNotificationChange={
                                          handleInnerNotificationChange
                                        }
                                        errors={errors}
                                      />
                                    );
                                  }
                                )}
                              </FlexColumn>
                            );
                          })}
                        </FlexRowWrapped>
                      ) : (
                        <FormInputContainer key={index}>
                          <FlexRow style={{ position: "relative" }}>
                            <Label>
                              {metadata[key].fieldName}:
                              {metadata[key].required ? (
                                <>
                                  &nbsp;{" "}
                                  <Subtitle2
                                    style={{
                                      color:
                                        tokens.colorPaletteCranberryBorderActive,
                                    }}
                                  >
                                    *
                                  </Subtitle2>{" "}
                                </>
                              ) : null}
                            </Label>
                          </FlexRow>
                          <FormInput
                            permission={"admin:>="}
                            value={editGameConfig[key] || ""}
                            style={{
                              marginTop: "5px",
                              pointerEvents: !metadata[key].editable
                                ? "none"
                                : "unset",
                            }}
                            placeholder={metadata[key].fieldName}
                            onChange={handleChange(key)}
                            errorMessage={errors[key]}
                            type={metadata[key].type || "string"}
                          />
                        </FormInputContainer>
                      )}
                    </section>
                  );
                })}
              </FlexRowWrapped>
            </FlexRow>
          </>
        ) : null}
      </FormContainer>

      {adminAndStronger ? (
        <Button
          className={buttonClasses.primary}
          appearance="primary"
          style={{ width: "250px", margin: "20px 0" }}
          onClick={onEditClick}
        >
          Save
        </Button>
      ) : null}
    </FlexColumn>
  );
};

export default GameConfigPage;

const Notifications = (props: {
  notificationKey: string;
  index: number;
  metadata: any;
  outerKey: string;
  innerKey: string;
  editGameConfig: any;
  handleInnerNotificationChange: any;
  errors: any;
  permission?: string;
}) => {
  const {
    notificationKey,
    metadata,
    outerKey,
    innerKey,
    editGameConfig,
    handleInnerNotificationChange,
    errors,
    permission,
  } = props;

  return (
    <FormInputContainer>
      {notificationKey !== "fieldName" ? (
        <>
          <FlexRow style={{ position: "relative" }}>
            <Label>
              {metadata[outerKey][innerKey][notificationKey].fieldName}:
              {metadata[outerKey][innerKey][notificationKey].required ? (
                <>
                  &nbsp;{" "}
                  <Subtitle2
                    style={{ color: tokens.colorPaletteCranberryBorderActive }}
                  >
                    *
                  </Subtitle2>{" "}
                </>
              ) : null}
            </Label>
          </FlexRow>
          {metadata[outerKey][innerKey][notificationKey].type === "boolean" ? (
            <SwitchInput
              permission={permission}
              checked={editGameConfig[outerKey][innerKey][notificationKey]}
              setChecked={handleInnerNotificationChange}
              onChange={(ev: any) => {
                handleInnerNotificationChange(
                  outerKey,
                  innerKey,
                  notificationKey,
                  ev.currentTarget.checked
                );
              }}
              label={""}
            />
          ) : (
            <FormInput
              permission={permission}
              value={editGameConfig[outerKey][innerKey][notificationKey] || ""}
              style={{
                marginTop: "5px",
                pointerEvents: !metadata[outerKey][innerKey][notificationKey]
                  .editable
                  ? "none"
                  : "unset",
              }}
              placeholder={
                metadata[outerKey][innerKey][notificationKey].fieldName
              }
              onChange={(ev) =>
                handleInnerNotificationChange(
                  outerKey,
                  innerKey,
                  notificationKey,
                  ev.target.value
                )
              }
              errorMessage={errors[outerKey]}
              type={
                metadata[outerKey][innerKey][notificationKey].type || "string"
              }
            />
          )}
        </>
      ) : null}
    </FormInputContainer>
  );
};

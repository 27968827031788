import { Routes as Switch, Route, Navigate } from "react-router-dom";
import Logout from "../components/logout/Logout";
import MainAppLayout from "../components/hoc/MainAppLayout";
import { ROUTES } from "./constants";
import usePermission from "../hooks/usePermission";
import { ROUTES_PATHS } from "../common";

export const NavigationSwitch = () => {
  const adminAndStronger = usePermission("admin:>=");
  return (
    <Switch>
      {ROUTES.map((route) => {
        return (
          <Route
            key={route.path}
            path={route.path}
            element={
              <MainAppLayout
                allowToAccess={!route.adminOnly || adminAndStronger}
              >
                {<route.mainComponent metadata={route.metadata || null} />}
              </MainAppLayout>
            }
          />
        );
      })}

      <Route path={ROUTES_PATHS.Auth.logout} element={<Logout />} />
      <Route
        path="/"
        element={
          <Navigate
            to={
              adminAndStronger
                ? ROUTES_PATHS.Dashboard.library
                : ROUTES_PATHS.Dashboard.accounts
            }
            replace
          />
        }
      />
      <Route
        path="*"
        element={
          <Navigate
            to={
              adminAndStronger
                ? ROUTES_PATHS.Dashboard.library
                : ROUTES_PATHS.Dashboard.accounts
            }
            replace
          />
        }
      />
    </Switch>
  );
};

import { PayloadAction } from "@reduxjs/toolkit";
import { call, put, takeLatest } from "redux-saga/effects";
import {
  alertError,
  alertSuccess,
} from "../notifications-manager/notification-manager";
import {
  getSceneItemStart,
  getSceneItemSuccess,
  getSceneItemFailed,
  updateSceneItemStart,
  updateSceneItemSuccess,
  updateSceneItemFailed,
  createSceneItemStart,
  createSceneItemSuccess,
  createSceneItemFailed,
  deleteSceneItemStart,
  deleteSceneItemSuccess,
  deleteSceneItemFailed,
} from "./edit-scene-item-slice";
import {
  ICreateSceneItemBody,
  IGetSceneItemParams,
  IRemoveSceneItemsParams,
  IUpdateSceneItemBody,
} from "../../../interfaces/api/scenes";
import scenesApi from "../../api/scenes-api";

function* createSceneItem(action: PayloadAction<ICreateSceneItemBody>): any {
  try {
    const sceneItemData = action.payload;
    const { data, error } = yield call(
      scenesApi.createSceneItem,
      sceneItemData
    );
    if (error) {
      yield put(createSceneItemFailed(error));
      return;
    }
    yield put(createSceneItemSuccess(data));
  } catch (error: any) {
    yield put(createSceneItemFailed(error));
    yield put(alertError(error.message));
  }
}

function* getSceneItem(action: PayloadAction<IGetSceneItemParams>): any {
  try {
    const { sceneItemId } = action.payload;
    const { data, error } = yield call(scenesApi.getSceneItem, { sceneItemId });
    if (error) {
      throw error;
    }
    yield put(getSceneItemSuccess(data));
  } catch (error: any) {
    yield put(getSceneItemFailed(error));
    yield put(alertError(error.message));
  }
}

function* removeSceneItem(action: PayloadAction<IRemoveSceneItemsParams>): any {
  try {
    const { sceneId, sceneItemId } = action.payload;
    const { data, error } = yield call(scenesApi.removeSceneItem, {
      sceneId,
      sceneItemId,
    });
    if (error) {
      throw error;
    }
    yield put(deleteSceneItemSuccess(data));
    yield put(alertSuccess("Item deleted successfully"));
  } catch (error: any) {
    yield put(deleteSceneItemFailed(error));
    yield put(alertError(error.message));
  }
}

function* updateSceneItem(action: PayloadAction<IUpdateSceneItemBody>): any {
  try {
    const params = action.payload;
    const { data, error } = yield call(scenesApi.updateSceneItem, params);
    if (error) {
      yield put(updateSceneItemFailed(error));
      return;
    }
    yield put(updateSceneItemSuccess(data));
  } catch (error: any) {
    yield put(updateSceneItemFailed(error));
    yield put(alertError(error.message));
  }
}

function* editSceneItemSaga() {
  yield takeLatest(createSceneItemStart.type, createSceneItem);
  yield takeLatest(getSceneItemStart.type, getSceneItem);
  yield takeLatest(deleteSceneItemStart.type, removeSceneItem);
  yield takeLatest(updateSceneItemStart.type, updateSceneItem);
}

export default editSceneItemSaga;

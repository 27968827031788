import styled from "styled-components";
import { TopNavFullHeight } from "../navigation/StyledComponents";
import { TopNavigation } from "../navigation/TopNavigation";
import PageLayout from "./PageLayout";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

export const MainAppContainer = styled.div`
  display: flex;
  flex-direction: row;
  position: fixed;
  top: ${TopNavFullHeight + "px"};
  width: 100%;
  height: ${`calc(100% - ${TopNavFullHeight + "px"})`};
`;

const MainAppLayout = ({
  children,
  allowToAccess,
}: {
  children: any;
  allowToAccess?: boolean;
}) => {
  const navigate = useNavigate();

  useEffect(() => {
    if (!allowToAccess) {
      navigate("/", { replace: true });
    }
  }, [allowToAccess]);
  return (
    <>
      <TopNavigation />
      <MainAppContainer>
        <PageLayout>{children}</PageLayout>
      </MainAppContainer>
    </>
  );
};

export default MainAppLayout;

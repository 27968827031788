import { useCallback, useEffect, useState } from "react";
import {
  Button,
  LargeTitle,
  Subtitle2,
  Label,
  tokens,
  Image,
  Divider,
  Dropdown,
  useId,
  Option,
} from "@fluentui/react-components";
import { IosArrowLtr24Filled } from "@fluentui/react-icons";
import { useParams, useNavigate } from "react-router-dom";
import FormInput from "../ui/inputs/FormInput";
import LoaderContainer from "../ui/loaders/LoaderContainer";
import { useForm } from "../../hooks/useForm";
import { useButtonStyles, useIconsStyles } from "../../hooks/useStyles";
import { useAppDispatch, useAppSelector } from "../../hooks/reduxHooks";
import { ROUTES_PATHS } from "../../common/constants";
import {
  FormContainer,
  FormInputContainer,
  TitleContainer,
  FlexColumn,
  FlexRowSpaced,
  FlexRowWrapped,
  FlexRow,
} from "../common/containers";
import ErrorMsg from "../common/ErrorMsg";
import ChooseLibraryImg from "../choose-library-img/ChooseLibraryImg";
import { getImageSource } from "../../utils/files-utils";
import PublishSwitch from "./PublishSwitch";
import PrizesTable from "./prizes/PrizesTable";
import {
  cleanEditBoxState,
  createBoxStart,
  getBoxStart,
  publishBoxStart,
  updateBoxStart,
} from "../../store/features/edit-box/edit-box-slice";
import { ICreateBoxBody, IUpdateBoxBody } from "../../interfaces/api/boxes";
import { Box, boxesTypeOptions } from "../../interfaces/models/box";
import {
  getInitialFormValues,
  getValidationsObj,
} from "../../utils/forms-utils";
import usePermission from "../../hooks/usePermission";

const BoxPage = ({ metadata }: { metadata: any }) => {
  const { id } = useParams();
  const currBox = useAppSelector((state) => state.editBox.box);
  const { operation, requestState, loading } = useAppSelector(
    (state) => state.editBox
  );
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const iconsClasses = useIconsStyles();

  useEffect(() => {
    dispatch(cleanEditBoxState());
    if (id !== "add-new") dispatch(getBoxStart({ giftBoxId: id || "" }));
  }, []);

  useEffect(() => {
    if (
      requestState === "finish" &&
      ["delete", "update"].includes(operation || "")
    ) {
      navigate(ROUTES_PATHS.Dashboard.boxes);
    } else if (requestState === "finish" && operation === "create" && currBox) {
      navigate(ROUTES_PATHS.Box.boxPage.replace(":id", currBox._id));
    }
  }, [operation, requestState]);

  return (
    <FlexColumn style={{ padding: " 0 3rem" }}>
      {loading ? <LoaderContainer /> : null}
      <FlexRowSpaced>
        <TitleContainer>
          <IosArrowLtr24Filled
            onClick={() => {
              navigate(ROUTES_PATHS.Dashboard.boxes);
            }}
            className={iconsClasses.loginArrow}
          />
          <LargeTitle>Box</LargeTitle>
        </TitleContainer>
      </FlexRowSpaced>
      {id === "add-new" ? (
        <BoxPageForm isEdit={false} box={null} metadata={metadata.createForm} />
      ) : currBox ? (
        <BoxPageForm isEdit={true} box={currBox} metadata={metadata.editForm} />
      ) : null}
    </FlexColumn>
  );
};

const BoxPageForm = (props: { box: any; metadata: any; isEdit: boolean }) => {
  const { box, metadata, isEdit } = props;

  const { id } = useParams();
  const currBox = useAppSelector((state) => state.editBox.box);
  const loading = useAppSelector((state) => state.editBox.loading);
  const boxPrizes = useAppSelector((state) => state.editBox.boxPrizes);
  const [selectedSortOptions, setSelectedSortOptions] = useState<any[]>([]);
  const [dropdownValue, setDropdownValue] = useState(
    boxesTypeOptions.find((item) => item.value === box?.boxType)?.label || ""
  );
  const [chanceSum, setChanceSum] = useState<number>(0);
  const [isPublished, setIsPublished] = useState(false);
  const writeAndStronger = usePermission("write:>=");

  const dispatch = useAppDispatch();

  const buttonClasses = useButtonStyles();
  const dropdownId = useId("dropdown");

  useEffect(() => {
    let chanceSumTemp = 0;
    boxPrizes.forEach((prize) => {
      chanceSumTemp = chanceSumTemp + prize.chance;
    });
    setChanceSum(chanceSumTemp);
  }, [boxPrizes]);

  useEffect(() => {
    setIsPublished(currBox?.isPublished || false);
  }, [currBox]);

  const onEditSubmit = (data: Box) => {
    if (isEdit) {
      const params: IUpdateBoxBody = {
        id: data._id,
        data: {
          name: data.name,
          price: data.price,
          boxType: data.boxType,
        },
      };
      dispatch(updateBoxStart(params));
    } else {
      dispatch(createBoxStart(editBox as ICreateBoxBody));
    }
  };

  const {
    handleSubmit,
    handleChange,
    handleCustomChange,
    data: editBox,
    errors,
  } = useForm<typeof box>({
    initialValues: getInitialFormValues(box),
    validations: getValidationsObj(metadata),
    onSubmit: () => {
      if (onEditSubmit) {
        onEditSubmit(editBox);
      }
    },
  });

  const onEditClick = () => {
    handleSubmit();
  };

  const onOptionSelect = (ev: any, data: any, key: string) => {
    const typeVal = boxesTypeOptions.find(
      (item) => item.id === data.selectedOptions[0]
    )?.value;
    handleCustomChange(key, typeVal || "");
    setSelectedSortOptions(data.selectedOptions);
    setDropdownValue(data.optionText ?? "");
  };

  const onPublishChange = useCallback(
    (ev: any) => {
      setIsPublished(ev.currentTarget.checked);
      dispatch(
        publishBoxStart({
          params: { giftBoxId: id || "", publish: ev.currentTarget.checked },
        })
      );
    },
    [setIsPublished]
  );

  return (
    <FlexColumn>
      <FormContainer style={{ opacity: loading ? "0.3" : "1" }}>
        {metadata != null ? (
          <>
            <FlexRow>
              <FlexRowWrapped style={{ gap: "40px" }}>
                {Object.keys(metadata).map((key, index) => {
                  return metadata[key] && metadata[key].type !== "image" ? (
                    <FormInputContainer key={index}>
                      <Label>
                        {metadata[key].fieldName}:
                        {metadata[key].required ? (
                          <>
                            &nbsp;{" "}
                            <Subtitle2
                              style={{
                                color: tokens.colorPaletteCranberryBorderActive,
                              }}
                            >
                              *
                            </Subtitle2>{" "}
                          </>
                        ) : null}
                      </Label>
                      {metadata[key].type === "dropdown" ? (
                        <>
                          <Dropdown
                            disabled={!writeAndStronger}
                            aria-labelledby={`${dropdownId}-underline`}
                            placeholder={metadata[key].fieldName}
                            value={dropdownValue}
                            selectedOptions={selectedSortOptions}
                            onOptionSelect={(ev, data) =>
                              onOptionSelect(ev, data, key)
                            }
                            style={{
                              position: "relative",
                              zIndex: "1",
                              marginTop: "5px",
                              pointerEvents:
                                !metadata[key].editable || isPublished
                                  ? "none"
                                  : "unset",
                            }}
                          >
                            {boxesTypeOptions.map((option, index) => {
                              return (
                                <Option key={option.id} value={option.id}>
                                  {option.label}
                                </Option>
                              );
                            })}
                          </Dropdown>
                          {errors[key] ? (
                            <ErrorMsg
                              message={errors[key] || ""}
                              withIcon={true}
                            />
                          ) : null}
                        </>
                      ) : (
                        <FormInput
                          value={editBox[key] || ""}
                          style={{
                            marginTop: "5px",
                            pointerEvents:
                              !metadata[key].editable || isPublished
                                ? "none"
                                : "unset",
                          }}
                          placeholder={metadata[key].fieldName}
                          onChange={handleChange(key)}
                          errorMessage={errors[key]}
                          type={metadata[key].type || "string"}
                        />
                      )}
                    </FormInputContainer>
                  ) : null;
                })}
              </FlexRowWrapped>
              <FlexColumn style={{ maxWidth: "126px" }}>
                <PublishSwitch
                  disabledSwitch={
                    !isEdit || !(chanceSum <= 101 && chanceSum >= 99)
                  }
                  checked={isPublished}
                  setChecked={setIsPublished}
                  withUnpublish={true}
                  withAlert={false}
                  onChange={onPublishChange}
                />
                {!(chanceSum <= 101 && chanceSum >= 99) ? (
                  <ErrorMsg
                    message={"The chances of prizes must add up to 100"}
                    withIcon={false}
                  />
                ) : null}
              </FlexColumn>
            </FlexRow>

            <FlexRowWrapped>
              {Object.keys(metadata).map((key, index) => {
                return metadata[key] && metadata[key].type === "image" ? (
                  <FormInputContainer style={{ width: "unset" }} key={index}>
                    <Label>
                      {metadata[key].fieldName}:
                      {metadata[key].required ? (
                        <>
                          &nbsp;{" "}
                          <Subtitle2
                            style={{
                              color: tokens.colorPaletteCranberryBorderActive,
                            }}
                          >
                            *
                          </Subtitle2>{" "}
                        </>
                      ) : null}
                    </Label>
                    {!metadata[key].editable || editBox[key] ? (
                      <>
                        <Image
                          width={150}
                          src={getImageSource(editBox[key]) || undefined}
                        />
                        <ChooseLibraryImg
                          onChosenImg={(img: string) =>
                            handleCustomChange(key, img)
                          }
                          btnTitle={"Change Image"}
                        />
                      </>
                    ) : (
                      <>
                        <ChooseLibraryImg
                          onChosenImg={(img: string) =>
                            handleCustomChange(key, img)
                          }
                        />
                        {errors[key] ? (
                          <ErrorMsg
                            message={errors[key] || ""}
                            withIcon={true}
                          />
                        ) : null}
                      </>
                    )}
                  </FormInputContainer>
                ) : null;
              })}
            </FlexRowWrapped>
          </>
        ) : null}
      </FormContainer>

      <FlexColumn style={{ margin: "30px 0 40px" }}>
        <Divider />
      </FlexColumn>

      <PrizesTable isEdit={isEdit} boxId={box?._id} isPublished={isPublished} />

      {writeAndStronger ? (
        <Button
          className={buttonClasses.primary}
          disabled={isPublished}
          appearance="primary"
          style={{ width: "250px", margin: "20px 0" }}
          onClick={onEditClick}
        >
          Save
        </Button>
      ) : null}
    </FlexColumn>
  );
};

export default BoxPage;

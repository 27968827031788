//Interfaces
import { ISubscription } from "../../../../interfaces/models";

import { Form } from "../../../form";
import { getDetails } from "../../utils";
import { SUBSCRIPTION_FORM } from "./constants";

export const SubscriptionPageForm = ({
  subscription,
}: {
  subscription: ISubscription;
}) => {
  const details = getDetails(SUBSCRIPTION_FORM, subscription);

  return <Form details={details} row={subscription} />;
};

import React from "react";
import {
  BrowserRouter as Router,
  Routes as Switch,
  Route,
  Navigate,
} from "react-router-dom";
import { ROUTES_PATHS } from "../../common/constants";
import Login from "./Login";
import ResetPassword from "./ResetPassword";

const LoginNavigation = () => {
  return (
    <Router>
      <Switch>
        <Route path={ROUTES_PATHS.Auth.login} element={<Login />}></Route>
        <Route
          path={ROUTES_PATHS.Auth.resetPassword}
          element={<ResetPassword />}
        ></Route>
        <Route
          path="*"
          element={<Navigate to={ROUTES_PATHS.Auth.login} replace />}
        />
      </Switch>
    </Router>
  );
};

export default LoginNavigation;

import FormInput from "../../../ui/inputs/FormInput";

export const UserLevel = ({ value }: { value: any }) => {
  return (
    <FormInput
      value={(value || 0) + 1}
      style={{
        marginTop: "5px",
        pointerEvents: "none",
        width: "100%",
      }}
    />
  );
};

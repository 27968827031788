import axiosInstance from "../../config/axios";
import {
  IGetAccountParams,
  IGetAccountsParams,
} from "../../interfaces/api/accounts";
import ServerRoutes from "./routes";
import { getParamsUrl, handleAxiosError } from "./utils/common";

const getAccounts = async (params: IGetAccountsParams) => {
  try {
    const url = getParamsUrl(ServerRoutes.accounts.accounts, params);
    const { status, data } = await axiosInstance.get(url);
    return { status, data };
  } catch (error: any) {
    const err = handleAxiosError(error);
    let message =
      "Some error happen, please try again. If the error returns please contact support";
    switch (err.status) {
      case 500:
        message = "Server error. Please contact support";
        break;
      default:
        break;
    }
    return {
      error: {
        message: message,
        status: err.status,
      },
    };
  }
};

const getAccountById = async (params: IGetAccountParams) => {
  try {
    let url = `${ServerRoutes.accounts.getAccount}/?id=${params.id}`;
    const { status, data } = await axiosInstance.get(url);

    return { status, data };
  } catch (error: any) {
    const err = handleAxiosError(error);
    let message =
      "Some error happen, please try again. If the error returns please contact support";
    switch (err.status) {
      case 404:
        message = "Account not found.";
        break;
      case 500:
        message = "Server error. Please contact support";
        break;
      default:
        break;
    }
    return {
      error: {
        message: message,
        status: err.status,
      },
    };
  }
};

export const accountsApi = {
  getAccounts,
  getAccountById,
};

//Style
import { useIconsStyles } from "../../hooks/useStyles";
import { LargeTitle } from "@fluentui/react-components";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { IosArrowLtr24Filled } from "@fluentui/react-icons";

//Common
import { ROUTES_PATHS } from "common";
import { FlexColumn } from "../common/containers";
import { TitleContainer } from "../common/containers";

//Hooks
import { useAsync } from "../../hooks/useAsync";

//Interfaces
import { ISubscription } from "../../interfaces/models";

//Service
import { subscriptionApi } from "../../store/api";

//Components
import { SubscriptionPageForm } from "./components";

export const SubscriptionPage = () => {
  const { id } = useParams();
  const location = useLocation();

  const search = location.state?.search;

  const iconsClasses = useIconsStyles();
  const navigate = useNavigate();

  const { value: subscription } = useAsync<ISubscription>(
    () => subscriptionApi.getSubscriptionById({ id: id || "" }),
    [id]
  );

  return (
    <FlexColumn style={{ padding: " 0 3rem" }}>
      <TitleContainer>
        <IosArrowLtr24Filled
          onClick={() => {
            navigate(ROUTES_PATHS.Dashboard.subscription, {
              state: { search },
            });
          }}
          className={iconsClasses.loginArrow}
        />
        <LargeTitle>Subscription</LargeTitle>
      </TitleContainer>
      {subscription && <SubscriptionPageForm subscription={subscription} />}
    </FlexColumn>
  );
};

import { useCallback, useEffect } from "react";
import Table, { columnFields } from "../table/Table";
import { useNavigate } from "react-router-dom";
import { ROUTES_PATHS } from "../../common/constants";
import { useAppDispatch, useAppSelector } from "../../hooks/reduxHooks";
import {
  DailyReward,
  dailyRewardTypeOptions,
  giftBoxTypeOptions,
} from "../../interfaces/models/daily-reward";
import { cleanEditDailyRewardState } from "../../store/features/edit-daily-reward/edit-daily-reward-slice";
import { fetchDailyRewardsStart } from "../../store/features/daily-rewards/daily-rewards-slice";

const columns: columnFields[] = [
  {
    fieldName: "day",
    columnName: "Day",
  },
  {
    fieldName: "prizeName",
    columnName: "Prize Name",
  },
  {
    fieldName: "prize",
    columnName: "Prize",
    type: "dropdownVal",
  },
  {
    fieldName: "amount",
    columnName: "Amount",
  },
  {
    fieldName: "giftBoxType",
    columnName: "Gift Box Type",
    type: "dropdownVal",
  },
  {
    fieldName: "createdAt",
    columnName: "Created At",
    type: "date",
  },
];

const DailyRewards = () => {
  const dailyRewards = useAppSelector(
    (state) => state.dailyRewards.dailyRewards
  );
  const loading = useAppSelector((state) => state.dailyRewards.loading);
  const limit = 20;
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const onEditClick = (dailyReward: DailyReward) => {
    navigate(
      ROUTES_PATHS.DailyReward.dailyRewardPage.replace(":id", dailyReward._id)
    );
  };
  const onAddNewClick = () => {
    navigate(
      ROUTES_PATHS.DailyReward.dailyRewardPage.replace(":id", "add-new")
    );
  };

  useEffect(() => {
    dispatch(cleanEditDailyRewardState());
  }, []);

  const getDailyRewards = useCallback((page: number, search?: string) => {
    dispatch(fetchDailyRewardsStart());
  }, []);

  return (
    <Table<DailyReward>
      title={"Daily Rewards"}
      list={dailyRewards}
      columns={columns}
      getItems={getDailyRewards}
      limit={limit}
      page={1}
      total={dailyRewards?.length}
      onEditClick={onEditClick}
      withAddNew={true}
      onAddNewClick={onAddNewClick}
      loading={loading}
      withSearch={false}
      dropdownOptions={{
        prize: dailyRewardTypeOptions,
        giftBoxType: giftBoxTypeOptions,
      }}
    />
  );
};

export default DailyRewards;

import {
  NavBar,
  NavItem,
  NavBarContainer,
  NavStyledNavLink,
} from "../../StyledComponents";
import { Text } from "@fluentui/react-components";

//Constants
import { NAVBAR_ROUTES } from "./constants";

//Hooks
import usePermission from "../../../../hooks/usePermission";

export const NavRoutes = () => {
  const adminAndStronger = usePermission("admin:>=");
  const isAllow = (isAdmin: boolean) => !isAdmin || adminAndStronger;
  return (
    <NavBarContainer>
      <NavBar>
        {NAVBAR_ROUTES.map(({ isAdmin, route, text }, index) => (
          <span key={index}>
            {isAllow(isAdmin) && (
              <NavItem key={index}>
                <NavStyledNavLink to={route}>
                  <Text>{text}</Text>
                </NavStyledNavLink>
              </NavItem>
            )}
          </span>
        ))}
      </NavBar>
    </NavBarContainer>
  );
};

import FormInput from "../../../ui/inputs/FormInput";

export const Inventories = ({ value }: { value: any }) => {
  return (
    <FormInput
      value={value || 0}
      style={{
        marginTop: "5px",
        pointerEvents: "none",
        width: "100%",
      }}
    />
  );
};

import { useCallback, useEffect, useState } from "react";
import {
  Button,
  LargeTitle,
  Subtitle2,
  Label,
  tokens,
  Image,
  Dropdown,
  Option,
} from "@fluentui/react-components";
import { IosArrowLtr24Filled } from "@fluentui/react-icons";
import { useParams, useNavigate } from "react-router-dom";
import FormInput from "../ui/inputs/FormInput";
import LoaderContainer from "../ui/loaders/LoaderContainer";
import { useForm } from "../../hooks/useForm";
import { useButtonStyles, useIconsStyles } from "../../hooks/useStyles";
import { useAppDispatch, useAppSelector } from "../../hooks/reduxHooks";
import { ROUTES_PATHS } from "../../common/constants";
import {
  FormContainer,
  FormInputContainer,
  TitleContainer,
  FlexColumn,
  FlexRowSpaced,
  FlexRowWrapped,
  FlexRow,
} from "../common/containers";
import ErrorMsg from "../common/ErrorMsg";
import ChooseLibraryImg from "../choose-library-img/ChooseLibraryImg";
import { getImageSource } from "../../utils/files-utils";
import {
  cleanEditDailyRewardState,
  createDailyRewardStart,
  getDailyRewardStart,
  updateDailyRewardStart,
} from "../../store/features/edit-daily-reward/edit-daily-reward-slice";
import {
  ICreateDailyRewardBody,
  IUpdateDailyRewardBody,
} from "../../interfaces/api/daily-rewards";
import { DailyReward } from "../../interfaces/models/daily-reward";
import {
  getInitialFormValues,
  getValidationsObj,
} from "../../utils/forms-utils";
import SwitchInput from "../common/Switch";
import usePermission from "../../hooks/usePermission";

const DailyRewardPage = (props: { metadata: any }) => {
  const { id } = useParams();
  const currDailyReward = useAppSelector(
    (state) => state.editDailyReward.dailyReward
  );
  const { operation, requestState, loading } = useAppSelector(
    (state) => state.editDailyReward
  );
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const iconsClasses = useIconsStyles();

  useEffect(() => {
    dispatch(cleanEditDailyRewardState());
    if (id !== "add-new")
      dispatch(getDailyRewardStart({ dailyRewardId: id || "" }));
  }, []);

  useEffect(() => {
    if (
      requestState === "finish" &&
      ["delete", "update", "create"].includes(operation || "")
    ) {
      navigate(ROUTES_PATHS.Dashboard.dailyRewards);
    }
  }, [operation, requestState]);

  return (
    <FlexColumn style={{ padding: " 0 3rem" }}>
      {loading ? <LoaderContainer /> : null}
      <FlexRowSpaced>
        <TitleContainer>
          <IosArrowLtr24Filled
            onClick={() => {
              navigate(ROUTES_PATHS.Dashboard.dailyRewards);
            }}
            className={iconsClasses.loginArrow}
          />
          <LargeTitle>Daily Reward</LargeTitle>
        </TitleContainer>
      </FlexRowSpaced>
      {id === "add-new" ? (
        <DailyRewardPageForm
          isEdit={false}
          dailyReward={null}
          metadata={props.metadata.createForm}
        />
      ) : currDailyReward ? (
        <DailyRewardPageForm
          isEdit={true}
          dailyReward={currDailyReward}
          metadata={props.metadata.editForm}
        />
      ) : null}
    </FlexColumn>
  );
};

const DailyRewardPageForm = (props: {
  dailyReward: DailyReward | null;
  metadata: any;
  isEdit: boolean;
}) => {
  const { dailyReward, metadata, isEdit } = props;

  const loading = useAppSelector((state) => state.editDailyReward.loading);
  const [selectedDropdownOptions, setSelectedDropdownOptions] = useState<any>({
    prize: [],
    giftBoxType: [],
  });
  const [dropdownValue, setDropdownValue] = useState<any>({
    prize:
      metadata.prize.dropdownVals?.find(
        (item: any) => item.value === dailyReward?.prize
      )?.label || null,
    giftBoxType:
      metadata.giftBoxType.dropdownVals?.find(
        (item: any) => item.value === dailyReward?.giftBoxType
      )?.label || null,
  });
  const [showDropdown, setShowDropdown] = useState(
    dailyReward?.giftBoxType ? true : false
  );

  const dispatch = useAppDispatch();
  const buttonClasses = useButtonStyles();
  const writeAndStronger = usePermission("write:>=");

  const onEditSubmit = (data: DailyReward) => {
    if (isEdit) {
      const params: IUpdateDailyRewardBody = {
        id: data._id,
        data: {
          day: data.day,
          prizeName: data.prizeName,
          prize: data.prize,
          amount: data.amount,
          giftBoxType: data.giftBoxType,
        },
      };
      dispatch(updateDailyRewardStart(params));
    } else {
      dispatch(
        createDailyRewardStart(editDailyReward as ICreateDailyRewardBody)
      );
    }
  };

  const {
    handleSubmit,
    handleChange,
    handleCustomChange,
    data: editDailyReward,
    errors,
  } = useForm<any>({
    initialValues: getInitialFormValues(dailyReward),
    validations: getValidationsObj(metadata),
    onSubmit: () => {
      if (onEditSubmit) {
        onEditSubmit(editDailyReward);
      }
    },
  });

  const onEditClick = () => {
    handleSubmit();
  };

  const onOptionSelect = (ev: any, data: any, key: string) => {
    const typeVal = metadata[key].dropdownVals?.find(
      (item: any) => item.id === data.selectedOptions[0]
    )?.value;
    handleCustomChange(key, typeVal || "");
    setSelectedDropdownOptions({
      ...selectedDropdownOptions,
      [key]: data.selectedOptions,
    });
    setDropdownValue({ ...dropdownValue, [key]: data.optionText ?? "" });
  };

  const onSwitchChange = useCallback(
    (ev: any, key: any) => {
      setShowDropdown(ev.currentTarget.checked);
      if (!ev.currentTarget.checked) {
        console.log("editDailyReward", editDailyReward);
        handleCustomChange(key, null);
        setSelectedDropdownOptions({ ...selectedDropdownOptions, [key]: null });

        setDropdownValue({ ...dropdownValue, [key]: "" });
      }
    },
    [setShowDropdown, dropdownValue, setDropdownValue]
  );

  return (
    <FlexColumn>
      <FormContainer style={{ opacity: loading ? "0.3" : "1" }}>
        {metadata != null ? (
          <>
            <FlexRow>
              <FlexRowWrapped style={{ gap: "40px" }}>
                {Object.keys(metadata).map((key, index) => {
                  return metadata[key] && metadata[key].type !== "image" ? (
                    <FormInputContainer key={index}>
                      <FlexRow style={{ position: "relative" }}>
                        <Label>
                          {metadata[key].fieldName}:
                          {metadata[key].required ? (
                            <>
                              &nbsp;{" "}
                              <Subtitle2
                                style={{
                                  color:
                                    tokens.colorPaletteCranberryBorderActive,
                                }}
                              >
                                *
                              </Subtitle2>{" "}
                            </>
                          ) : null}
                        </Label>
                        <FlexColumn
                          style={{
                            position: "absolute",
                            left: "85px",
                            bottom: "-9px",
                          }}
                        >
                          {metadata[key].withSwitch ? (
                            <SwitchInput
                              disabledSwitch={!writeAndStronger}
                              checked={showDropdown}
                              setChecked={setShowDropdown}
                              onChange={(ev: any) => onSwitchChange(ev, key)}
                              label={""}
                            />
                          ) : null}
                        </FlexColumn>
                      </FlexRow>

                      {metadata[key].type === "dropdown" ? (
                        <>
                          {!metadata[key].withSwitch || showDropdown ? (
                            <>
                              <Dropdown
                                placeholder={metadata[key].fieldName}
                                value={dropdownValue[key] || ""}
                                selectedOptions={selectedDropdownOptions.key}
                                onOptionSelect={(ev, data) =>
                                  onOptionSelect(ev, data, key)
                                }
                                style={{
                                  position: "relative",
                                  zIndex: "1",
                                  marginTop: "10px",
                                  pointerEvents: !metadata[key].editable
                                    ? "none"
                                    : "unset",
                                }}
                              >
                                {metadata[key].dropdownVals?.map(
                                  (option: any, index: any) => {
                                    return (
                                      <Option key={option.id} value={option.id}>
                                        {option.label}
                                      </Option>
                                    );
                                  }
                                )}
                              </Dropdown>
                              {errors[key] ? (
                                <ErrorMsg
                                  message={errors[key] || ""}
                                  withIcon={true}
                                />
                              ) : null}
                            </>
                          ) : null}
                        </>
                      ) : (
                        <FormInput
                          value={editDailyReward[key] || ""}
                          style={{
                            marginTop: "5px",
                            pointerEvents: !metadata[key].editable
                              ? "none"
                              : "unset",
                          }}
                          placeholder={metadata[key].fieldName}
                          onChange={handleChange(key)}
                          errorMessage={errors[key]}
                          type={metadata[key].type || "string"}
                        />
                      )}
                    </FormInputContainer>
                  ) : null;
                })}
              </FlexRowWrapped>
            </FlexRow>

            <FlexRowWrapped>
              {Object.keys(metadata).map((key, index) => {
                return metadata[key] && metadata[key].type === "image" ? (
                  <FormInputContainer style={{ width: "unset" }} key={index}>
                    <Label>
                      {metadata[key].fieldName}:
                      {metadata[key].required ? (
                        <>
                          &nbsp;{" "}
                          <Subtitle2
                            style={{
                              color: tokens.colorPaletteCranberryBorderActive,
                            }}
                          >
                            *
                          </Subtitle2>{" "}
                        </>
                      ) : null}
                    </Label>
                    {!metadata[key].editable || editDailyReward[key] ? (
                      <>
                        <Image
                          width={150}
                          src={
                            getImageSource(editDailyReward[key]) || undefined
                          }
                        />
                        <ChooseLibraryImg
                          onChosenImg={(img: string) =>
                            handleCustomChange(key, img)
                          }
                          btnTitle={"Change Image"}
                        />
                      </>
                    ) : (
                      <>
                        <ChooseLibraryImg
                          onChosenImg={(img: string) =>
                            handleCustomChange(key, img)
                          }
                        />
                        {errors[key] ? (
                          <ErrorMsg
                            message={errors[key] || ""}
                            withIcon={true}
                          />
                        ) : null}
                      </>
                    )}
                  </FormInputContainer>
                ) : null;
              })}
            </FlexRowWrapped>
          </>
        ) : null}
      </FormContainer>

      {writeAndStronger ? (
        <Button
          className={buttonClasses.primary}
          appearance="primary"
          style={{ width: "250px", margin: "20px 0" }}
          onClick={onEditClick}
        >
          Save
        </Button>
      ) : null}
    </FlexColumn>
  );
};

export default DailyRewardPage;

import { Button } from "@mui/material";
import { styled } from "@mui/system";
import { fonts } from "../../../../theme/theme";

export const OpenPopupButton = styled(Button)(() => ({
  height: "fit-content",
  width: "fit-content",
  color: "#0897a7",
  fontFamily: fonts.fontFamily.bold,
  fontSize: "1.2rem",
}));

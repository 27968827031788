import { PayloadAction } from "@reduxjs/toolkit";
import { call, put, takeLatest } from "redux-saga/effects";
import {
  alertError,
  alertSuccess,
} from "../notifications-manager/notification-manager";
import {
  getBoxPrizeStart,
  getBoxPrizeSuccess,
  getBoxPrizeFailed,
  updateBoxPrizeStart,
  updateBoxPrizeSuccess,
  updateBoxPrizeFailed,
  createBoxPrizeStart,
  createBoxPrizeSuccess,
  createBoxPrizeFailed,
  deleteBoxPrizeStart,
  deleteBoxPrizeSuccess,
  deleteBoxPrizeFailed,
} from "./edit-box-prize-slice";
import {
  ICreateBoxPrizeBody,
  IGetBoxPrizeParams,
  IRemoveBoxPrizeParams,
  IUpdateBoxPrizeBody,
} from "../../../interfaces/api/boxes";
import boxesApi from "../../api/boxes-api";

function* createBoxPrize(action: PayloadAction<ICreateBoxPrizeBody>): any {
  try {
    const boxPrizeData = action.payload;
    const { data, error } = yield call(boxesApi.createBoxPrize, boxPrizeData);
    if (error) {
      yield put(createBoxPrizeFailed(error));
      return;
    }
    yield put(createBoxPrizeSuccess(data));
  } catch (error: any) {
    yield put(createBoxPrizeFailed(error));
    yield put(alertError(error.message));
  }
}

function* getBoxPrize(action: PayloadAction<IGetBoxPrizeParams>): any {
  try {
    const { giftBoxPrizeId } = action.payload;
    const { data, error } = yield call(boxesApi.getBoxPrize, {
      giftBoxPrizeId,
    });
    if (error) {
      throw error;
    }
    yield put(getBoxPrizeSuccess(data));
  } catch (error: any) {
    yield put(getBoxPrizeFailed(error));
    yield put(alertError(error.message));
  }
}

function* removeBoxPrize(action: PayloadAction<IRemoveBoxPrizeParams>): any {
  try {
    const { giftBoxId, giftBoxPrizeId } = action.payload;
    const { data, error } = yield call(boxesApi.removeBoxPrize, {
      giftBoxId,
      giftBoxPrizeId,
    });
    if (error) {
      throw error;
    }
    yield put(deleteBoxPrizeSuccess(data));
    yield put(alertSuccess("Item deleted successfully"));
  } catch (error: any) {
    yield put(deleteBoxPrizeFailed(error));
    yield put(alertError(error.message));
  }
}

function* updateBoxPrize(action: PayloadAction<IUpdateBoxPrizeBody>): any {
  try {
    const params = action.payload;
    const { data, error } = yield call(boxesApi.updateBoxPrize, params);
    if (error) {
      yield put(updateBoxPrizeFailed(error));
      return;
    }
    yield put(updateBoxPrizeSuccess(data));
  } catch (error: any) {
    yield put(updateBoxPrizeFailed(error));
    yield put(alertError(error.message));
  }
}

function* editBoxPrizeSaga() {
  yield takeLatest(createBoxPrizeStart.type, createBoxPrize);
  yield takeLatest(getBoxPrizeStart.type, getBoxPrize);
  yield takeLatest(deleteBoxPrizeStart.type, removeBoxPrize);
  yield takeLatest(updateBoxPrizeStart.type, updateBoxPrize);
}

export default editBoxPrizeSaga;

import { Label, Subtitle2, tokens } from "@fluentui/react-components";
import { FormContainerRow } from "../common/containers";
import FormInput from "../ui/inputs/FormInput";
import { DetailsContainer } from "./Form.styled";

export const Form = ({
  details,
  row,
}: {
  details: {
    label: string;
    value: any;
    required?: boolean;
    editable?: boolean;
    key: string;
    Components?: (props: { value: string; row: any }) => JSX.Element;
  }[][];
  row: any;
}) => {
  return (
    <>
      {details.map((pairs, index) => (
        <FormContainerRow key={index}>
          {pairs.map(
            ({ label, value, editable, required, Components }, index) => (
              <DetailsContainer key={index}>
                <Label>
                  {label}:
                  {required && (
                    <Subtitle2
                      style={{
                        color: tokens.colorPaletteCranberryBorderActive,
                      }}
                    >
                      {" *"}
                    </Subtitle2>
                  )}
                </Label>

                {Components ? (
                  <Components value={value} row={row} />
                ) : (
                  <FormInput
                    value={value || ""}
                    style={{
                      marginTop: "5px",
                      pointerEvents: editable ? "unset" : "none",
                      width: "100%",
                    }}
                    placeholder={label}
                  />
                )}
              </DetailsContainer>
            )
          )}
        </FormContainerRow>
      ))}
    </>
  );
};

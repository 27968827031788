import { useEffect } from "react";

//Style
import { useIconsStyles } from "../../hooks/useStyles";
import { LargeTitle } from "@fluentui/react-components";
import { IosArrowLtr24Filled } from "@fluentui/react-icons";

//Common
import { ROUTES_PATHS } from "common";
import { FlexColumn } from "../common/containers";
import { TitleContainer } from "../common/containers";

//Utils
import { getAccountStart } from "../../store/features/edit-account/edit-account-slice";

//Hooks
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../hooks/reduxHooks";

//Components
import { AccountPageForm } from "./components";

export const AccountPage = () => {
  const { id } = useParams();
  const currAccount = useAppSelector((state) => state.editAccount.account);
  const iconsClasses = useIconsStyles();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();

  const search = location.state?.search;

  useEffect(() => {
    dispatch(getAccountStart({ id: id || "" }));
  }, [id]);

  return (
    <FlexColumn style={{ padding: " 0 3rem" }}>
      <TitleContainer>
        <IosArrowLtr24Filled
          onClick={() => {
            navigate(ROUTES_PATHS.Dashboard.accounts, {
              state: { search },
            });
          }}
          className={iconsClasses.loginArrow}
        />
        <LargeTitle>Account</LargeTitle>
      </TitleContainer>
      {currAccount && <AccountPageForm fullAccount={currAccount} />}
    </FlexColumn>
  );
};

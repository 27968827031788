import { forwardRef, useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Slide,
} from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import { CloseRounded } from "@mui/icons-material";

//Components
import { FlexRow } from "../../../common/containers";
import Table, { columnFields } from "../../../table/Table";

//Interfaces
import { IBreathingCycle } from "../../../../interfaces/models/breathing-cycle";

//Utils
import { getTimeDifference } from "../../../../utils";

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const columns: columnFields[] = [
  {
    fieldName: "_id",
    columnName: "Session ID",
  },
  {
    fieldName: "sessionStart",
    columnName: "Session Start",
  },
  {
    fieldName: "sessionComplete",
    columnName: "Session End",
  },
  {
    fieldName: "timeDiff",
    columnName: "Session Time",
    costumeRenderColumn: (_, row) => {
      return (
        <FlexRow
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          {row["sessionComplete"]
            ? getTimeDifference(row["sessionStart"], row["sessionComplete"])
            : "00:00"}
        </FlexRow>
      );
    },
  },
  {
    fieldName: "breathingCyclesCount",
    columnName: "Breathing Cycles",
  },
  {
    fieldName: "keysAwarded",
    columnName: "Key Awarded",
    type: "boolean",
  },
  {
    fieldName: "batteryStatus",
    columnName: "Battery Status ",
  },
  {
    fieldName: "deviceId",
    columnName: "Device ID",
  },
  {
    fieldName: "createdAt",
    columnName: "Created at",
    type: "date",
  },
];

export const BreathingCyclePopup = ({
  open,
  onClose,
  breathingCycles,
  isPending,
  total,
  getItems,
}: {
  open?: boolean;
  breathingCycles: IBreathingCycle[];
  onClose: () => void;
  isPending: boolean;
  total: number;
  getItems: Function;
}) => {
  const [list, setList] = useState({
    data: breathingCycles || [],
    metadata: { page: 1, total },
  });

  const handleItemChange = (page: number) => {
    getItems(page).then((data: any) => setList(data));
  };
  return (
    <Dialog
      open={!!open}
      fullWidth
      maxWidth={"xl"}
      scroll="paper"
      TransitionComponent={Transition}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle>Breathing Cycles</DialogTitle>
      <IconButton
        aria-label="close"
        onClick={onClose}
        sx={() => ({
          position: "absolute",
          right: 8,
          top: 8,
        })}
      >
        <CloseRounded />
      </IconButton>
      <DialogContent style={{ height: "100%" }}>
        {breathingCycles && (
          <Table<IBreathingCycle>
            list={list.data}
            columns={columns}
            getItems={handleItemChange}
            limit={10}
            total={total}
            page={list.metadata.page}
            loading={isPending}
          />
        )}
      </DialogContent>
    </Dialog>
  );
};

export const getTimeFormat = (data: Date) => {
  try {
    return data.toTimeString().slice(0, 5);
  } catch (error) {
    return "";
  }
};
export const dateFormat = (date: Date, options?: { withTime?: boolean }) => {
  try {
    let res = date.toLocaleDateString("en-GB").replaceAll("/", ".");
    if (options?.withTime) {
      res += `\t${getTimeFormat(date)}`;
    }
    return res;
  } catch (error) {
    return "";
  }
};

export const formatDateTime = (dt: Date) => {
  try {
    const m = formatdigits(dt.getMonth() + 1);
    const d = formatdigits(dt.getDate());
    const y = formatdigits(dt.getFullYear());
    const date = `${m}/${d}/${y}`;
    const h = formatdigits(
      dt.getHours() > 12 ? dt.getHours() - 12 : dt.getHours()
    );
    const min = formatdigits(dt.getMinutes());
    const s = formatdigits(dt.getSeconds());
    const time = `${h}:${min}:${s}`;
    const ampm = dt.getHours() > 11 ? "PM" : "AM";
    return `${date} ${time} ${ampm}`;
  } catch (error) {
    return "";
  }
};
const formatdigits = (val: string | number | any[]) => {
  val = val.toString();
  return val.length === 1 ? "0" + val : val;
};

export const getTimeDifference = (date1: string, date2: string): string => {
  const d1 = new Date(date1);
  const d2 = new Date(date2);

  // Calculate the time difference in milliseconds
  const diffMs = d2.getTime() - d1.getTime();

  // Convert milliseconds to absolute seconds
  const diffSeconds = Math.abs(Math.floor(diffMs / 1000));

  // Calculate minutes and seconds
  const minutes = Math.floor(diffSeconds / 60);
  const seconds = diffSeconds % 60;

  // Format minutes and seconds as MM:SS
  const formattedMinutes = minutes.toString().padStart(2, "0");
  const formattedSeconds = seconds.toString().padStart(2, "0");

  const formattedTime = `${formattedMinutes}:${formattedSeconds}`;

  // Handle negative difference
  return diffMs < 0 ? `-${formattedTime}` : formattedTime;
};

export const isSmallerThan = (date1: Date, date2: Date): boolean =>
  date1.getTime() < date2.getTime();

export const isLargerThan = (date1: Date, date2: Date): boolean =>
  date1.getTime() > date2.getTime();

export const isSmallerOrEqual = (date1: Date, date2: Date): boolean =>
  date1.getTime() <= date2.getTime();

export const isLargerOrEqual = (date1: Date, date2: Date): boolean =>
  date1.getTime() >= date2.getTime();

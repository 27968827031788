import { createRef, useRef, ClipboardEvent } from "react";
import styled from "styled-components";

import { isNumber } from "../../../utils/utils";
import { RelativeContainer } from "../../common/containers";
import { StyledInput } from "./Input";

const Container = styled(RelativeContainer)`
  display: grid;
`;

interface IPinInput {
  value: string[];
  setValue: Function;
  disabled?: boolean;
  numOfDigits: number;
}
const PinInput = ({ value, setValue, disabled, numOfDigits }: IPinInput) => {
  const refs: any = useRef(value.map(() => createRef()));

  const handlePaste = (event: ClipboardEvent) => {
    event.preventDefault();
    const pasteData = event.clipboardData.getData("Text");
    //only numbers
    if (/^[0-9]*$/.test(pasteData)) {
      setValue([...pasteData]);
      refs.current[5].current.focus();
    }
  };

  return (
    <Container
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-evenly",
      }}
    >
      {Array.from(Array(numOfDigits)).map((_, index) => (
        <StyledInput
          ref={refs.current[index]}
          key={index}
          varient={"underline"}
          style={{
            width: "40px",
            textAlign: "center",
            borderBottom: "1px solid black",
          }}
          value={value[index]}
          onChange={() => {}}
          onPaste={handlePaste}
          onKeyDown={({ key }) => {
            if (key === "Backspace") {
              let update = [...value];
              update[index] = "";
              setValue(update);
              if (index > 0) {
                refs.current[index - 1].current.focus();
              }
              return;
            }
            if (isNumber(key)) {
              let update = [...value];
              update[index] = key;
              setValue(update);
              if (index + 1 < numOfDigits) {
                refs.current[index + 1].current.focus();
              }
            }
          }}
          disabled={disabled}
          placeholder={""}
          type={"text"}
          className={value[index] === "" ? "invalid" : ""}
        />
      ))}
    </Container>
  );
};

export default PinInput;

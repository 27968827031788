import styled from "styled-components";
import { tokens } from "@fluentui/react-components";

import { ReactComponent as ArrowIcon } from "../../assets/icons/chevron-icon.svg";

import Theme from "../../theme/theme";

const PaginationContainer = styled.div`
  display: flex;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
  padding: 30px;
  width: 100%;
`;

const PaginationButton = styled.button`
  cursor: pointer;
  border: none;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 4px;
  outline: none;
  background-color: transparent;
  font-size: 20px;
  height: 40px;
  width: 40px;
  &.active {
    background-color: #5b5fc6;
    color: white;
  }

  &:hover {
    background-color: ${(props) =>
      props.disabled ? "none" : tokens.colorNeutralBackground5};
    color: black;
  }
`;

const EdgeButton = styled(PaginationButton)`
  align-self: center;
  min-width: 0;
  color: black;
`;

interface IPaginationContainer {
  col?: boolean;
  alignCenter?: boolean;
  justifyCenter?: boolean;
}
const Container = styled.div<IPaginationContainer>`
  display: flex;
  flex-direction: ${(props) => (props.col ? "column" : "row")};
  align-items: ${(props) => (props.alignCenter ? "center" : "start")};
  justify-content: ${(props) => (props.justifyCenter ? "center" : "start")};
  font-family: ${Theme.fonts.fontFamily.bold};
  font-size: 16px;
  color: ${Theme.palette.typography.secondery};
`;

interface IPageCounter {
  pageIndex: number;
  pageCount: number;
}
const PageCounter = ({ pageIndex, pageCount }: IPageCounter) => {
  return (
    <Container style={{ marginTop: "4px", fontSize: "16px" }}>
      <div style={{ marginRight: "4px" }}>Page</div>
      <strong>
        {pageCount === 0 ? 0 : pageIndex + 1} of {pageCount}
      </strong>
    </Container>
  );
};

interface IPageButtons extends IPageCounter {
  onPageChange: (index: number) => any;
  totalPageButtons: number;
}
const PageButtons = ({
  pageIndex,
  pageCount,
  onPageChange,
  totalPageButtons,
}: IPageButtons) => {
  let pagesButtons = [];
  let pagesOnEachSide = Math.floor(totalPageButtons / 2);
  let leftSideDifference = Math.max(
    0,
    pagesOnEachSide + pageIndex + 1 - pageCount
  );
  let rightSideDifference = Math.max(0, pagesOnEachSide - pageIndex);
  for (
    let i = Math.max(1, pageIndex + 1 - pagesOnEachSide - leftSideDifference);
    i <=
    Math.min(pageIndex + 1 + pagesOnEachSide + rightSideDifference, pageCount);
    i++
  ) {
    pagesButtons.push(
      <PaginationButton
        className={i === pageIndex ? "active" : ""}
        key={i}
        onClick={() => onPageChange(i)}
      >
        {i}
      </PaginationButton>
    );
  }
  return <>{pagesButtons}</>;
};

interface IPagination {
  onPageChange: (index: number) => any;
  pageIndex: number;
  pageCount: number;
  totalPageButtons: number;
  withEdges?: boolean;
  withCounter?: boolean;
}
const Pagination = ({
  onPageChange,
  pageIndex,
  pageCount,
  totalPageButtons,
  withEdges,
  withCounter,
}: IPagination) => {
  return (
    <PaginationContainer>
      <Container col alignCenter>
        <Container>
          {withEdges && (
            <PaginationButton
              onClick={() => onPageChange(0)}
              disabled={pageIndex === 0}
            >
              {"<<"}
            </PaginationButton>
          )}
          {!(pageIndex === 1) ? (
            <EdgeButton
              // varient='ghost'
              disabled={pageIndex === 1}
              onClick={() => onPageChange(pageIndex - 1)}
            >
              <ArrowIcon width={10} height={15} fill={"black"} />
            </EdgeButton>
          ) : (
            <div style={{ width: "52px" }} />
          )}

          <PageButtons
            pageIndex={pageIndex}
            pageCount={pageCount}
            onPageChange={onPageChange}
            totalPageButtons={totalPageButtons}
          />
          {!(pageIndex === pageCount) && pageCount > 0 ? (
            <EdgeButton
              // varient='ghost'
              disabled={pageIndex === pageCount}
              onClick={() => onPageChange(pageIndex + 1)}
            >
              <ArrowIcon
                width={10}
                height={15}
                fill={"black"}
                transform="rotate(180)"
              />
            </EdgeButton>
          ) : (
            <div style={{ width: "52px" }} />
          )}
          {withEdges && (
            <PaginationButton
              onClick={() => onPageChange(pageCount)}
              disabled={pageIndex === pageCount - 1}
            >
              {">>"}
            </PaginationButton>
          )}
        </Container>
        {withCounter && (
          <PageCounter pageIndex={pageIndex} pageCount={pageCount} />
        )}
      </Container>
    </PaginationContainer>
  );
};

export default Pagination;

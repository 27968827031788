//Components
import Table, { columnFields } from "../table/Table";
import { FlexRow } from "../common/containers";

//Common
import { ROUTES_PATHS } from "../../common";

//Interface
import { ISubscription } from "../../interfaces/models";

//Hooks
import { useLocation, useNavigate } from "react-router-dom";
import { useSubscriptions } from "./hooks/useSubscriptions";

//Utils
import { dateFormat, isSmallerThan } from "../../utils";
import { addDays } from "date-fns";

const columns: columnFields[] = [
  {
    fieldName: "subscription_email",
    columnName: "Email",
  },
  {
    fieldName: "subscription_phone_number",
    columnName: "Phone number",
  },
  {
    fieldName: "activation_code",
    columnName: "Activation Code",
  },
  {
    fieldName: "end_subscription_date",
    columnName: "Subscription end-date",
    type: "date",
    costumeRenderColumn: (value) => {
      const date = new Date(value);
      return (
        <FlexRow
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            ...(isSmallerThan(date, addDays(new Date(), 30)) && {
              color: "orange",
              fontWeight: 800,
            }),
            ...(isSmallerThan(date, new Date()) && {
              color: "red",
            }),
          }}
        >
          {dateFormat(date, { withTime: false })}
        </FlexRow>
      );
    },
  },
  {
    fieldName: "app_user_email",
    columnName: "App User Email",
  },
  {
    fieldName: "app_user_phone_number",
    columnName: "App User Phone",
  },
];

export const Subscription = () => {
  const navigate = useNavigate();

  const location = useLocation();

  const LIMIT = 100;

  const onEditClick = (account: ISubscription, search: string) => {
    navigate(
      ROUTES_PATHS.Subscription.subscriptionPage.replace(":id", account._id),
      { state: { search } }
    );
  };

  const {
    page,
    list: subscriptions,
    total,
    status,
    getData,
    sortValue,
    sortOption,
    setSortValue,
    selectedSortOptions,
    setSelectedSortOptions,
  } = useSubscriptions(LIMIT);

  return (
    <Table<ISubscription>
      title={"Subscriptions"}
      list={subscriptions}
      columns={columns}
      getItems={getData}
      limit={LIMIT}
      page={page}
      total={total || subscriptions.length}
      onEditClick={onEditClick}
      loading={status === "pending"}
      sortOptions={sortOption}
      selectedSortOptions={selectedSortOptions}
      setSelectedSortOptions={setSelectedSortOptions}
      sortValue={sortValue}
      setSortValue={setSortValue}
      withSearch
      initialSearch={location.state?.search || ""}
    />
  );
};

import styled from "styled-components";
import { IThemed } from "../../interfaces/base";

const Box = styled.div<IThemed>`
  background-color: ${(props) => props.theme.palette.background.light};
  box-shadow: 0px 3px 14px 4px rgba(0, 0, 0, 0.14);
  border-radius: 4px;
  overflow: hidden;
`;

export default Box;

//External Libraries
import _ from "lodash";
import {
  Button,
  LargeTitle,
  Subtitle1,
  Subtitle2,
  Spinner,
  Subtitle2Stronger,
  mergeClasses,
} from "@fluentui/react-components";
import { KeyboardEvent, useCallback, useState, useEffect } from "react";

//Hooks
import {
  useEmailValidator,
  usePasswordValidator,
} from "../../hooks/validators/useRegExValidator";
import { useForm } from "../../hooks/useForm";
import { useAppDispatch, useAppSelector } from "../../hooks/reduxHooks";
import {
  useButtonStyles,
  useIconsStyles,
  useTypographyStyles,
} from "../../hooks/useStyles";

//Style
import FormInput from "../ui/inputs/FormInput";
import PinInput from "../ui/inputs/PinInput";
import {
  Buttoncontainer,
  Container,
  Spinnercontainer,
  TitleContainer,
} from "./StyledComponents";
import {
  EyeOff20Regular,
  Eye20Regular,
  IosArrowLtr24Filled,
} from "@fluentui/react-icons";

//Interfaces
import {
  IForgotPassword,
  ILoginForm,
} from "../../interfaces/components/forms/login";
import {
  forgotPasswordStart,
  loginStart,
  resendCodeStart,
  resetVerifyCode,
  verifyCodeStart,
} from "../../store/features/auth-user/auth-user-slice";

const LoginView = ({
  onLoginSubmit,
  setView,
}: {
  onLoginSubmit: any;
  setView: any;
}) => {
  const auth = useAppSelector((state) => state.auth);

  const {
    handleSubmit,
    handleChange,
    data: loginState,
    errors,
  } = useForm<ILoginForm>({
    validations: {
      email: {
        custom: {
          isValid: () => emailValidation,
          message: "Invalid Email Address",
        },
      },
      password: {
        custom: {
          isValid: () => passwordValidation,
          message:
            "Password should contain at least one number, one lower case letter, one capital letter and one special character ",
        },
      },
    },
    onSubmit: () => {
      if (onLoginSubmit) {
        onLoginSubmit(loginState);
      }
    },
  });

  const emailValidation = useEmailValidator(loginState.email);
  const passwordValidation = usePasswordValidator(loginState.password);
  const [showPassword, setShowPassword] = useState(false);
  const buttonClasses = useButtonStyles();
  const typographyClasses = useTypographyStyles();
  const iconsClasses = useIconsStyles();

  const onPasswordIconClick = () => {
    setShowPassword(!showPassword);
  };

  const onLoginClick = () => {
    handleSubmit();
  };

  const handleKeyDown = ({ key }: KeyboardEvent<HTMLInputElement>) => {
    if (key === "Enter") {
      onLoginClick();
    }
  };

  return (
    <Container style={{ width: "355px" }}>
      <LargeTitle>Login</LargeTitle>

      <FormInput
        value={loginState?.email || ""}
        type={"email"}
        placeholder={"Email Address"}
        onChange={handleChange("email")}
        variant={"underline"}
        onKeyDown={handleKeyDown}
        errorMessage={errors.email}
      />

      <FormInput
        value={loginState.password || ""}
        type={showPassword ? "text" : "password"}
        placeholder={"Password"}
        onChange={handleChange("password")}
        onKeyDown={handleKeyDown}
        variant={"underline"}
        errorMessage={errors.password}
        contentAfter={
          showPassword ? (
            <EyeOff20Regular
              onClick={onPasswordIconClick}
              className={iconsClasses.clickableIcon}
            />
          ) : (
            <Eye20Regular
              onClick={onPasswordIconClick}
              className={iconsClasses.clickableIcon}
            />
          )
        }
      />

      {/* <Button
                appearance={'transparent'}
                style={{
                    marginTop: '10px',
                    width: 'fit-content',
                    alignSelf: 'end',
                    paddingRight: '0px'
                }}
                onClick={() => setView('reset')}>
                Forgot Password?
            </Button> */}

      <Buttoncontainer>
        <Button
          className={mergeClasses(
            buttonClasses.primary,
            buttonClasses.fullWidth
          )}
          appearance="primary"
          onClick={onLoginClick}
        >
          Login
        </Button>
        {auth.error ? (
          <Subtitle2Stronger
            onClick={() => {
              setView("reset");
            }}
            className={typographyClasses.error}
          >
            {auth.error.message}
          </Subtitle2Stronger>
        ) : null}
      </Buttoncontainer>
      <Spinnercontainer>{auth.loading ? <Spinner /> : null}</Spinnercontainer>
    </Container>
  );
};

const VerifyCodeView = ({
  onVerifyCodeClick,
  setView,
}: {
  onVerifyCodeClick: any;
  setView: any;
}) => {
  const NUM_OF_DIGITS = 6;
  const dispatch = useAppDispatch();
  const auth = useAppSelector((state) => state.auth);
  const [pinCode, setPinCode] = useState<string[]>(["", "", "", "", "", ""]);
  const [error, setError] = useState<string | null>(null);
  const buttonClasses = useButtonStyles();
  const iconsClasses = useIconsStyles();
  const typographyClasses = useTypographyStyles();

  const resendCode = useCallback(
    _.debounce(() => {
      if (auth.verificationToken && !auth.loading) {
        dispatch(resendCodeStart({ token: auth.verificationToken }));
      }
    }, 500),
    []
  );

  const checkCode = () => {
    let valid = true;
    // for (let c of pinCode) {
    //     if (c === '') {
    //         valid = false;
    //     }
    // }
    setError(!valid ? "Invalid Code" : null);
    return valid;
  };

  return (
    <Container>
      <TitleContainer>
        <IosArrowLtr24Filled
          onClick={() => {
            dispatch(resetVerifyCode());
            setView("login");
          }}
          className={iconsClasses.loginArrow}
        />
        <LargeTitle>Login</LargeTitle>
      </TitleContainer>

      <Subtitle2 style={{ marginBottom: "20px" }}>
        {`Please enter the verification code sent to the email address ${auth.loginForm?.email} `}
      </Subtitle2>

      <PinInput
        value={pinCode}
        setValue={setPinCode}
        numOfDigits={NUM_OF_DIGITS}
      />
      {error ? (
        <Subtitle2Stronger
          onClick={() => {
            setView("reset");
          }}
          className={typographyClasses.error}
        >
          {error}
        </Subtitle2Stronger>
      ) : null}

      <Buttoncontainer>
        <Button
          className={mergeClasses(
            buttonClasses.primary,
            buttonClasses.fullWidth
          )}
          appearance="primary"
          onClick={() => {
            if (checkCode()) {
              onVerifyCodeClick(pinCode.join(""));
            }
          }}
        >
          Login
        </Button>
        {auth.error ? (
          <Subtitle2Stronger
            onClick={() => {
              setView("reset");
            }}
            className={typographyClasses.error}
          >
            {auth.error.message}
          </Subtitle2Stronger>
        ) : null}
      </Buttoncontainer>

      <Subtitle1
        align={"center"}
        onClick={resendCode}
        underline
        style={{ cursor: "pointer" }}
      >
        Didn't receive the code? Send again
      </Subtitle1>

      <Spinnercontainer>{auth.loading ? <Spinner /> : null}</Spinnercontainer>
    </Container>
  );
};

const ForgotPasswordView = (props: { setView: any }) => {
  const { setView } = props;

  const dispatch = useAppDispatch();
  const buttonClasses = useButtonStyles();
  const IconsClasses = useIconsStyles();
  const typographyClasses = useTypographyStyles();

  const { handleSubmit, handleChange, data, errors } = useForm<IForgotPassword>(
    {
      validations: {
        email: {
          custom: {
            isValid: () => emailValidation,
            message: "Invalid Email Address",
          },
        },
      },
      onSubmit: () => {
        onForgotPasswordClick();
      },
    }
  );
  const auth = useAppSelector((state) => state.auth);
  const emailValidation = useEmailValidator(data.email);

  const onForgotPasswordClick = () => {
    if (data.email) {
      const { email } = data;
      dispatch(forgotPasswordStart({ email }));
    }
  };

  return (
    <Container>
      <TitleContainer>
        <IosArrowLtr24Filled
          onClick={() => {
            setView("login");
          }}
          className={IconsClasses.loginArrow}
        />
        <LargeTitle align="center"> Reset Password</LargeTitle>
      </TitleContainer>

      {auth.operation === "forgotPassword" && auth.requestState === "finish" ? (
        <>
          <Subtitle2 style={{ marginBottom: "20px" }}>
            Password reset email sent successfully
          </Subtitle2>

          <Buttoncontainer style={{ width: "358px" }}>
            <Button
              className={mergeClasses(
                buttonClasses.primary,
                buttonClasses.fullWidth
              )}
              appearance="primary"
              onClick={() => {
                setView("login");
              }}
            >
              Login
            </Button>
          </Buttoncontainer>
        </>
      ) : (
        <>
          <Subtitle2 style={{ marginBottom: "20px" }}>
            Please enter email address and you will get a reset link by mail
          </Subtitle2>
          <FormInput
            value={data.email || ""}
            type={"email"}
            placeholder={"Email Address"}
            onChange={handleChange("email")}
            variant={"underline"}
            errorMessage={errors.email}
          />

          <Buttoncontainer>
            <Button
              className={mergeClasses(
                buttonClasses.primary,
                buttonClasses.fullWidth
              )}
              appearance="primary"
              onClick={handleSubmit}
            >
              Send
            </Button>
            {auth.error ? (
              <Subtitle2Stronger
                onClick={() => {
                  setView("reset");
                }}
                className={typographyClasses.error}
              >
                {auth.error.message}
              </Subtitle2Stronger>
            ) : null}
          </Buttoncontainer>
        </>
      )}
      <Spinnercontainer>{auth.loading ? <Spinner /> : null}</Spinnercontainer>
    </Container>
  );
};

const LoginForm = (props: {
  with2fa?: boolean;
  loginAction?: (data: ILoginForm) => any;
  verifyCodeAction?: any;
  resendCodeAction?: any;
  resetPasswordAction?: any;
}) => {
  const dispatch = useAppDispatch();
  const auth = useAppSelector((state) => state.auth);
  const [view, setView] = useState<"login" | "2fa" | "reset">("login");

  useEffect(() => {
    if (auth.verifyCode) {
      setView("2fa");
    }
  }, [auth.verifyCode]);

  const onLoginSubmit = (data: ILoginForm) => {
    const { email, password } = data;
    dispatch(
      loginStart({
        email,
        password,
      })
    );
  };

  const onVerifyCodeClick = (code: string) => {
    if (auth.verificationToken) {
      dispatch(verifyCodeStart({ code, token: auth.verificationToken }));
    }
  };

  return view === "reset" ? (
    <ForgotPasswordView setView={setView} />
  ) : view === "2fa" ? (
    <VerifyCodeView onVerifyCodeClick={onVerifyCodeClick} setView={setView} />
  ) : (
    <LoginView onLoginSubmit={onLoginSubmit} setView={setView} />
  );
};

export default LoginForm;

import FormInput from "../ui/inputs/FormInput";
import { dateFormat } from "../../utils";

export const DateFormatForm = ({ value }: { value: any; row: any }) => {
  return (
    <FormInput
      value={
        value ? dateFormat(new Date(value), { withTime: false }) : undefined
      }
      style={{
        marginTop: "5px",
        pointerEvents: "none",
        width: "100%",
      }}
    />
  );
};

import { FC } from "react";
import toast, { Toast } from "react-hot-toast";
import styled from "styled-components";

import { INotifiction } from "../../interfaces/store/notifications-manager";
import Theme from "../../theme/theme";
import Box from "../ui/Box";
import { Typography } from "../ui/Typography";

const Container = styled(Box)`
  width: 325px;
  height: 65px;
  cursor: pointer;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.1), 0 3px 3px rgba(0, 0, 0, 0.05);
  &:hover {
    transform: scale(1.01);
    transition: 0.2s ease-out;
  }
`;
const TopLine = styled.div<{ type: "success" | "error" | "info" }>((props) => {
  const color = props.type || "info";
  return `
        width: 100%;
        height: 5px;
        background-color: ${Theme.palette[color].light};
    `;
});
const TextContainer = styled.div`
  padding: 10px 25px;
`;

interface IAlertProps {
  alert: INotifiction;
  toastObject: Toast;
}

const Alert: FC<IAlertProps> = ({ alert, toastObject }) => {
  return (
    <Container
      onClick={() => {
        toast.remove(toastObject.id);
      }}
    >
      <TopLine type={alert.type} />
      <TextContainer>
        <Typography
          dir="auto"
          style={{ fontSize: "20px" }}
          color="primary"
          varient="subtitle1"
        >
          {alert.message}
        </Typography>
      </TextContainer>
    </Container>
  );
};

export default Alert;

//Common
import { FlexRow, FlexColumn } from "../../../common/containers";

//Interfaces
import { FullAccount } from "../../../../interfaces/models";
import { Device } from "../../../../interfaces/models/device";

//Utils
import { addDays } from "date-fns";
import { getDetails } from "../../utils";

//Constants
import { ACCOUNT_FORM } from "./constants";

//Components
import { Form } from "../../../form";
import Table, { columnFields } from "../../../table/Table";

//Hooks
import { useAsync } from "../../../../hooks/useAsync";

//Services
import { devicesApi } from "../../../../store/api";

const columns: columnFields[] = [
  {
    fieldName: "deviceId",
    columnName: "ID",
  },
  {
    fieldName: "name",
    columnName: "Name",
  },
  {
    fieldName: "version",
    columnName: "Version",
  },
  {
    fieldName: "lastTimeUsed",
    columnName: "Last Sean",
    type: "date",
    costumeRenderColumn: (value) => {
      const isTwoWeeks = new Date(value) < addDays(new Date(), -14);
      return (
        <FlexRow
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            ...(isTwoWeeks && { color: "red", fontWeight: 800 }),
          }}
        >
          {value}
        </FlexRow>
      );
    },
  },
];

export const UserDetails = ({ fullAccount }: { fullAccount: FullAccount }) => {
  const { value: devices, status: deviceStatus } = useAsync<Device[]>(
    () =>
      devicesApi.getUserDevices({
        userId: fullAccount.account._id,
      }),
    [fullAccount.account._id]
  );

  const details = getDetails(ACCOUNT_FORM, fullAccount);

  return (
    <FlexColumn>
      <Form details={details} row={fullAccount} />
      {devices && (
        <Table<Device>
          title={"Devices"}
          list={devices}
          columns={columns}
          getItems={() => null}
          smallTable
          limit={10}
          total={devices.length}
          withAddNew={false}
          loading={deviceStatus === "pending"}
          withSearch={false}
        />
      )}
    </FlexColumn>
  );
};

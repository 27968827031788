import { PayloadAction } from "@reduxjs/toolkit";
import { call, put, takeLatest } from "redux-saga/effects";
import { accountsApi } from "../../api/accounts-api";
import {
  fetchAccountsFailed,
  fetchAccountsStart,
  fetchAccountsSucess,
} from "./accounts-slice";
import { IGetAccountsParams } from "../../../interfaces/api/accounts";

function* fetchAccounts(
  action: PayloadAction<{ params: IGetAccountsParams }>
): any {
  try {
    const { params } = action.payload;
    const { data, error } = yield call(accountsApi.getAccounts, params);
    if (error) {
      yield put(fetchAccountsFailed(error));
      return;
    }
    yield put(fetchAccountsSucess(data));
  } catch (error: any) {
    yield put(fetchAccountsFailed(error));
  }
}

function* accountsSaga() {
  yield takeLatest(fetchAccountsStart.type, fetchAccounts);
}

export default accountsSaga;

import FormInput from "../../../ui/inputs/FormInput";

export const ProductName = ({ value }: { value: string }) => {
  const NAMES: { [x: string]: string } = {
    ["7167828820050"]: "UHG",
    ["7395228745810"]: "UHG Spanish",
    ["7168150601810"]: "Influencer",
    ["7168163577938"]: "HCP",
    ["7168166756434"]: "Beta",
    ["7232067141714"]: "Support",
  };

  return (
    <FormInput
      value={`${value} - ${NAMES[value] ?? "unknown"}`}
      style={{
        marginTop: "5px",
        pointerEvents: "none",
        width: "100%",
      }}
    />
  );
};

import { PayloadAction } from "@reduxjs/toolkit";
import { call, put, takeLatest } from "redux-saga/effects";
import {
  fetchLibraryFailed,
  fetchLibraryStart,
  fetchLibrarySucess,
} from "./library-slice";
import { IGetLibraryParams } from "../../../interfaces/api/library";
import libraryApi from "../../api/library-api";

function* fetchLibrary(
  action: PayloadAction<{ params: IGetLibraryParams }>
): any {
  try {
    const { params } = action.payload;

    delete params.page;

    const { data, error } = yield call(libraryApi.getLibrary, params);
    if (error) {
      yield put(fetchLibraryFailed(error));
      return;
    }
    yield put(fetchLibrarySucess(data));
  } catch (error: any) {
    yield put(fetchLibraryFailed(error));
  }
}

function* librarySaga() {
  yield takeLatest(fetchLibraryStart.type, fetchLibrary);
}

export default librarySaga;

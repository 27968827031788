export const getValidationsObj = (metadata: any) => {
  let validationsObj = {};

  if (metadata) {
    Object.keys(metadata).map((key, index) => {
      let validationRules = {};

      if (metadata[key].required) {
        validationRules = {
          ...validationRules,
          required: {
            value: true,
            message: "required",
          },
        };
      }
      validationsObj = {
        ...validationsObj,
        [key]: {
          ...validationRules,
        },
      };
    });
  }
  return validationsObj;
};

export const getInitialFormValues = (currItem: any) => ({ ...currItem });

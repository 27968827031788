import { ReactNode, useEffect, useMemo, useState } from "react";
import {
  FlexRow,
  FlexColumn,
  FlexRowSpaced,
  FlexRowCentered,
} from "../common/containers";

import { debounce } from "lodash";

//Styled Components
import {
  useId,
  Image,
  Input,
  Button,
  Option,
  Dropdown,
  DataGrid,
  Subtitle1,
  LargeTitle,
  DataGridRow,
  DataGridBody,
  DataGridCell,
  DataGridHeader,
  TableCellLayout,
  createTableColumn,
  DataGridHeaderCell,
} from "@fluentui/react-components";
import Pagination from "../ui/Pagination";
import LoaderContainer from "../ui/loaders/LoaderContainer";

import { Eye24Regular } from "@fluentui/react-icons";

//Utils
import { dateFormat } from "../../utils/time-utils";
import { getImageSource } from "../../utils/files-utils";

//Hooks
import usePermission from "../../hooks/usePermission";
import { useButtonStyles, useTableStyles } from "../../hooks/useStyles";

interface ITableProps<T> {
  list: T[];
  limit: number;
  columns: columnFields[];
  title?: string;
  getItems: Function;
  total: number;
  onEditClick?: Function;
  withAddNew?: boolean;
  onAddNewClick?: Function;
  page?: number;
  loading: boolean;
  sortOptions?: any[];
  selectedSortOptions?: any;
  setSelectedSortOptions?: any;
  sortValue?: string;
  setSortValue?: any;
  withSearch?: boolean;
  initialSearch?: string;
  disabledAddNew?: boolean;
  dropdownOptions?: any;
  withoutEditItems?: boolean;
  permission?: string;
  withViewRow?: boolean;
  smallTable?: boolean;
  numOfPages?: number;
}

export type columnFields = {
  fieldName: string;
  columnName: string;
  type?: "image" | "date" | "boolean" | "dropdownVal";
  costumeRenderColumn?: (value: any, row: any) => ReactNode;
};

export const getNumOfPages = (total: number, limit: number) => {
  try {
    if (!limit) return 0;
    return Math.ceil(total / limit);
  } catch (err) {
    return 0;
  }
};

const Table = <T extends object>({
  list,
  page,
  total,
  limit,
  title,
  loading,
  columns,
  getItems,
  sortValue,
  numOfPages,
  withAddNew,
  smallTable,
  withSearch,
  permission,
  withViewRow,
  sortOptions,
  onEditClick,
  setSortValue,
  onAddNewClick,
  disabledAddNew,
  dropdownOptions,
  selectedSortOptions,
  setSelectedSortOptions,
  initialSearch,
}: ITableProps<T>) => {
  const [search, setSearch] = useState<string>(initialSearch || "");
  const searchId = useId("input-search");
  const dropdownId = useId("dropdown");

  const writeAndStronger = usePermission(permission || "write:>=");

  const tableClasses = useTableStyles();
  const buttonClasses = useButtonStyles();

  useEffect(() => {
    debouncedGetItems(search);
  }, [search, sortValue]);

  const debouncedGetItems = useMemo(
    () =>
      debounce((query: string) => {
        getItems(1, query);
      }, 300),
    [getItems]
  );

  const getColumnsArr = (columns: columnFields[]) => {
    return columns.reduce((prev, column, index) => {
      return [
        ...prev,

        createTableColumn<T>({
          columnId: column.fieldName,
          renderHeaderCell: () => {
            return <b>{column.columnName}</b>;
          },
          renderCell: (item) => {
            const fieldVal = item[column.fieldName as keyof typeof item];
            if (column.costumeRenderColumn) {
              return column.costumeRenderColumn(fieldVal, item);
            }

            if (
              ["string", "boolean", "number", null, 0].includes(typeof fieldVal)
            ) {
              return (
                <TableCellLayout>
                  <FlexRow
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    {fieldVal === null ? (
                      "-"
                    ) : fieldVal === 0 ? (
                      "0"
                    ) : column.type === "image" ? (
                      <Image
                        src={getImageSource(fieldVal as string) || undefined}
                        height={100}
                      />
                    ) : column.type === "date" ? (
                      `${dateFormat(new Date(fieldVal as unknown as Date))}`
                    ) : column.type === "boolean" ? (
                      fieldVal ? (
                        column.columnName
                      ) : (
                        "-"
                      )
                    ) : column.type === "dropdownVal" &&
                      dropdownOptions[column.fieldName] ? (
                      dropdownOptions[column.fieldName].find(
                        (item: any) => item.value === fieldVal
                      )?.label
                    ) : (
                      fieldVal
                    )}
                  </FlexRow>
                </TableCellLayout>
              );
            } else {
              return (
                <TableCellLayout>
                  <FlexRow
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      flexDirection: "row-reverse",
                    }}
                  >
                    {index === columns.length - 1 &&
                    withViewRow &&
                    onEditClick ? (
                      <Eye24Regular
                        onClick={() => onEditClick(item, search)}
                        style={{
                          cursor: "pointer",
                          minWidth: "25px",
                          marginLeft: "10px",
                        }}
                      />
                    ) : null}
                  </FlexRow>
                </TableCellLayout>
              );
            }
          },
        }),
      ];
    }, [] as any[]);
  };

  const onOptionSelect = (_: any, data: any) => {
    setSelectedSortOptions(data.selectedOptions);
    setSortValue(data.optionText ?? "");
  };

  return (
    <FlexColumn>
      <FlexRowSpaced style={{ margin: ` 0 0 ${smallTable ? "0" : "40px"} 0` }}>
        <FlexRowCentered style={{ gap: "40px" }}>
          {smallTable ? (
            <Subtitle1>{title}</Subtitle1>
          ) : (
            <LargeTitle>{title}</LargeTitle>
          )}

          {writeAndStronger && withAddNew && onAddNewClick ? (
            <Button
              className={buttonClasses.primary}
              style={{ padding: "5px 10px" }}
              appearance="primary"
              onClick={() => onAddNewClick()}
              disabled={disabledAddNew}
            >
              Add New
            </Button>
          ) : null}
        </FlexRowCentered>

        <FlexRow style={{ gap: "40px" }}>
          {withSearch ? (
            <Input
              value={search || ""}
              onChange={(e) => {
                setSearch(e.currentTarget.value);
              }}
              type="search"
              placeholder="Search"
              appearance="underline"
              id={searchId}
              style={{
                width: "200px",
                height: "fit-content",
                alignSelf: "end",
              }}
            />
          ) : null}
          {sortOptions ? (
            <Dropdown
              aria-labelledby={`${dropdownId}-underline`}
              placeholder="Sort By"
              appearance="underline"
              value={sortValue}
              selectedOptions={selectedSortOptions}
              onOptionSelect={onOptionSelect}
              style={{ position: "relative", zIndex: "1" }}
            >
              {sortOptions.map((option) => {
                return (
                  <Option key={option.id} value={option.id}>
                    {option.label}
                  </Option>
                );
              })}
            </Dropdown>
          ) : null}
        </FlexRow>
      </FlexRowSpaced>
      {loading ? <LoaderContainer /> : null}

      <DataGrid
        items={list}
        columns={getColumnsArr(columns)}
        style={{ opacity: loading ? "0.3" : "1" }}
      >
        <DataGridHeader>
          <DataGridRow>
            {({ renderHeaderCell }) => (
              <DataGridHeaderCell
                style={{ borderBottom: "solid 1px gray" }}
                className={tableClasses.tableHeader}
              >
                {renderHeaderCell()}
              </DataGridHeaderCell>
            )}
          </DataGridRow>
        </DataGridHeader>
        <DataGridBody<T>>
          {({ item, rowId }) => (
            <DataGridRow<T>
              style={onEditClick ? { cursor: "pointer" } : undefined}
              key={rowId}
              onClick={() => onEditClick && onEditClick(item, search)}
            >
              {({ renderCell }) => (
                <DataGridCell>{renderCell(item)}</DataGridCell>
              )}
            </DataGridRow>
          )}
        </DataGridBody>

        {getNumOfPages(total, limit) > 1 && (
          <Pagination
            onPageChange={(page: number) => {
              getItems(page);
            }}
            pageIndex={page || 1}
            pageCount={getNumOfPages(total, limit)}
            totalPageButtons={numOfPages || 10}
          />
        )}
      </DataGrid>
      {total === 0 ? (
        <Subtitle1 style={{ margin: "30px" }} align="center">
          no results
        </Subtitle1>
      ) : null}
    </FlexColumn>
  );
};

export default Table;

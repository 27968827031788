import { call, put, takeLatest } from "redux-saga/effects";
import {
  fetchDailyRewardsFailed,
  fetchDailyRewardsStart,
  fetchDailyRewardsSuccess,
} from "./daily-rewards-slice";
import dailyRewardsApi from "../../api/daily-rewards-api";

function* fetchDailyRewards(): any {
  try {
    const { data, error } = yield call(dailyRewardsApi.getDailyRewards);
    if (error) {
      yield put(fetchDailyRewardsFailed(error));
      return;
    }

    yield put(fetchDailyRewardsSuccess(data));
  } catch (error: any) {
    yield put(fetchDailyRewardsFailed(error));
  }
}

function* dailyRewardsSaga() {
  yield takeLatest(fetchDailyRewardsStart.type, fetchDailyRewards);
}

export default dailyRewardsSaga;

import {
  Dialog,
  DialogTrigger,
  DialogSurface,
  DialogTitle,
  DialogBody,
  DialogActions,
  DialogContent,
  Button,
} from "@fluentui/react-components";
import { useModalStyles } from "../../hooks/useStyles";

export interface IModalPopupProps {
  title: string;
  open: boolean;
  onOpenChange: Function;
  onActionClick: any;
  actionTitle: string;
  modalContent: any;
  disabledAction?: boolean;
}

const AlertPopup = ({
  open,
  onOpenChange,
  title,
  modalContent,
  onActionClick,
  disabledAction,
  actionTitle,
}: IModalPopupProps) => {
  const modalClasses = useModalStyles();

  return (
    <Dialog open={open} onOpenChange={(_, data) => onOpenChange(data.open)}>
      <DialogSurface className={modalClasses.wideModal}>
        <DialogBody className={modalClasses.container}>
          <DialogTitle className={modalClasses.title}>{title}</DialogTitle>
          <DialogContent>{modalContent}</DialogContent>
          <DialogActions className={modalClasses.actions}>
            <DialogTrigger disableButtonEnhancement>
              <Button appearance="secondary">Close</Button>
            </DialogTrigger>
            <Button
              appearance="primary"
              onClick={onActionClick}
              disabled={disabledAction || false}
            >
              {actionTitle}
            </Button>
          </DialogActions>
        </DialogBody>
      </DialogSurface>
    </Dialog>
  );
};

export default AlertPopup;

import { PayloadAction } from "@reduxjs/toolkit";
import { call, put, takeLatest } from "redux-saga/effects";
import {
  alertError,
  alertSuccess,
} from "../notifications-manager/notification-manager";
import {
  deleteLibraryImageFailed,
  deleteLibraryImageStart,
  deleteLibraryImageSuccess,
  getLibraryImageFailed,
  getLibraryImageStart,
  getLibraryImageSuccess,
  updateLibraryImageFailed,
  updateLibraryImageStart,
  updateLibraryImageSuccess,
} from "./edit-library-image-slice";
import { IGetLibraryImageParams } from "../../../interfaces/api/library";
import libraryApi from "../../api/library-api";
import { IFileUpdate } from "../../../interfaces/api/files";

function* updateImage(action: PayloadAction<IFileUpdate>): any {
  try {
    const params = action.payload;
    const { data, error } = yield call(libraryApi.updateLibraryImage, params);
    if (error) {
      yield put(updateLibraryImageFailed(error));
      return;
    }
    yield put(updateLibraryImageSuccess(data));
  } catch (error: any) {
    yield put(updateLibraryImageFailed(error));
  }
}

function* getImageById(action: PayloadAction<IGetLibraryImageParams>): any {
  try {
    const { fileId } = action.payload;
    const { data, error } = yield call(libraryApi.getLibraryImageById, {
      fileId,
    });
    if (error) {
      throw error;
    }
    yield put(getLibraryImageSuccess(data));
  } catch (error: any) {
    yield put(getLibraryImageFailed(error));
    yield put(alertError(error.message));
  }
}

function* deleteImage(action: PayloadAction<IGetLibraryImageParams>): any {
  try {
    const { fileId } = action.payload;
    const { data, error } = yield call(libraryApi.deleteLibraryImage, {
      fileId,
    });
    if (error) {
      throw error;
    }
    yield put(deleteLibraryImageSuccess(data));
    yield put(alertSuccess("Image deleted successfully"));
  } catch (error: any) {
    yield put(deleteLibraryImageFailed(error));
    yield put(alertError(error.message));
  }
}

function* editLibraryImageSaga() {
  yield takeLatest(updateLibraryImageStart.type, updateImage);
  yield takeLatest(getLibraryImageStart.type, getImageById);
  yield takeLatest(deleteLibraryImageStart.type, deleteImage);
}

export default editLibraryImageSaga;

import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";

import FormInput from "../ui/inputs/FormInput";
import LoaderContainer from "../ui/loaders/LoaderContainer";
import { Scene } from "../../interfaces/models/scene";
import {
  ICreateSceneBody,
  IUpdateSceneBody,
} from "../../interfaces/api/scenes";
import {
  cleanEditSceneState,
  createSceneStart,
  fetchSceneItemsStart,
  getSceneStart,
  publishSceneStart,
  updateSceneStart,
} from "../../store/features/edit-scene/edit-scene-slice";
import ChooseLibraryImg from "../choose-library-img/ChooseLibraryImg";
import ItemsTable from "./items/ItemsTable";
import PublishSwitch from "./PublishSwitch";

//Styled Components
import {
  Button,
  LargeTitle,
  Subtitle2,
  Label,
  tokens,
  Image,
  Divider,
} from "@fluentui/react-components";
import { IosArrowLtr24Filled } from "@fluentui/react-icons";

//Common
import { ROUTES_PATHS } from "../../common/constants";
import {
  FormContainer,
  FormInputContainer,
  TitleContainer,
  FlexColumn,
  FlexRowSpaced,
  FlexRowWrapped,
  FlexRow,
} from "../common/containers";
import ErrorMsg from "../common/ErrorMsg";

//Utils
import {
  getInitialFormValues,
  getValidationsObj,
} from "../../utils/forms-utils";
import { getImageSource } from "../../utils/files-utils";

//Hooks
import { useForm } from "../../hooks/useForm";
import usePermission from "../../hooks/usePermission";
import { useButtonStyles, useIconsStyles } from "../../hooks/useStyles";
import { useAppDispatch, useAppSelector } from "../../hooks/reduxHooks";

const ScenePage = ({ metadata }: { metadata: any }) => {
  const { id } = useParams();
  const currScene = useAppSelector((state) => state.editScene.scene);
  const { operation, requestState, loading } = useAppSelector(
    (state) => state.editScene
  );
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const iconsClasses = useIconsStyles();

  useEffect(() => {
    dispatch(cleanEditSceneState());
    if (id !== "add-new" && id) {
      dispatch(getSceneStart({ sceneId: id || "" }));
      dispatch(fetchSceneItemsStart({ params: { sceneId: id } }));
    }
  }, []);

  useEffect(() => {
    if (
      requestState === "finish" &&
      ["delete", "update"].includes(operation || "")
    ) {
      navigate(ROUTES_PATHS.Dashboard.scenes);
    } else if (
      requestState === "finish" &&
      operation === "create" &&
      currScene
    ) {
      navigate(ROUTES_PATHS.Scene.scenePage.replace(":id", currScene._id));
    }
  }, [operation, requestState]);

  return (
    <FlexColumn style={{ padding: " 0 3rem" }}>
      {loading ? <LoaderContainer /> : null}
      <FlexRowSpaced>
        <TitleContainer>
          <IosArrowLtr24Filled
            onClick={() => {
              navigate(ROUTES_PATHS.Dashboard.scenes);
            }}
            className={iconsClasses.loginArrow}
          />
          <LargeTitle>Scene</LargeTitle>
        </TitleContainer>
      </FlexRowSpaced>
      {id === "add-new" ? (
        <ScenePageForm
          isEdit={false}
          scene={null}
          metadata={metadata.createForm}
        />
      ) : currScene ? (
        <ScenePageForm
          isEdit={true}
          scene={currScene}
          metadata={metadata.editForm}
        />
      ) : null}
    </FlexColumn>
  );
};

const ScenePageForm = (props: {
  scene: any;
  metadata: any;
  isEdit: boolean;
}) => {
  const { scene, metadata, isEdit } = props;

  const { id } = useParams();
  const currScene = useAppSelector((state) => state.editScene.scene);
  const loading = useAppSelector((state) => state.editScene.loading);
  const dispatch = useAppDispatch();
  const buttonClasses = useButtonStyles();
  const [isPublished, setIsPublished] = useState(false);
  const writeAndStronger = usePermission("write:>=");

  useEffect(() => {
    setIsPublished(currScene?.isPublished || false);
  }, [currScene]);

  const publishScene = () => {
    setIsPublished(true);
    dispatch(publishSceneStart({ sceneId: id || "" }));
  };

  const onEditSubmit = (data: Scene) => {
    if (isEdit) {
      const params: IUpdateSceneBody = {
        id: data._id,
        data: {
          imageBackgroundPath: data.imageBackgroundPath,
          imageCompletedPath: data.imageCompletedPath,
          imageThumbnailPath: data.imageThumbnailPath,
          name: data.name,
          requiredDeviceUses: data.requiredDeviceUses,
          serialNumber: data.serialNumber,
        },
      };
      dispatch(updateSceneStart(params));
    } else {
      dispatch(createSceneStart(editScene as ICreateSceneBody));
    }
  };

  const {
    handleSubmit,
    handleChange,
    handleCustomChange,
    data: editScene,
    errors,
  } = useForm<typeof scene>({
    initialValues: getInitialFormValues(scene),
    validations: getValidationsObj(metadata),
    onSubmit: () => {
      if (onEditSubmit) {
        onEditSubmit(editScene);
      }
    },
  });

  const onEditClick = () => {
    handleSubmit();
  };

  return (
    <FlexColumn>
      <FormContainer style={{ opacity: loading ? "0.3" : "1" }}>
        {metadata != null ? (
          <>
            <FlexRow>
              <FlexRowWrapped style={{ gap: "40px" }}>
                {Object.keys(metadata).map((key, index) => {
                  return metadata[key] && metadata[key].type !== "image" ? (
                    <FormInputContainer key={index}>
                      <Label>
                        {metadata[key].fieldName}:
                        {metadata[key].required ? (
                          <>
                            &nbsp;{" "}
                            <Subtitle2
                              style={{
                                color: tokens.colorPaletteCranberryBorderActive,
                              }}
                            >
                              *
                            </Subtitle2>{" "}
                          </>
                        ) : null}
                      </Label>
                      <FormInput
                        value={editScene[key] || ""}
                        style={{
                          marginTop: "5px",
                          pointerEvents:
                            metadata[key].editable || !scene ? "unset" : "none",
                        }}
                        placeholder={metadata[key].fieldName}
                        onChange={handleChange(key)}
                        errorMessage={errors[key]}
                        type={metadata[key].type || "string"}
                      />
                    </FormInputContainer>
                  ) : null;
                })}
              </FlexRowWrapped>
              <PublishSwitch
                disabledSwitch={!isEdit}
                checked={isPublished}
                setChecked={publishScene}
                withAlert={true}
              />
            </FlexRow>

            <FlexRowWrapped>
              {Object.keys(metadata).map((key, index) => {
                return metadata[key] && metadata[key].type === "image" ? (
                  <FormInputContainer style={{ width: "unset" }} key={index}>
                    <Label>
                      {metadata[key].fieldName}:
                      {metadata[key].required ? (
                        <>
                          &nbsp;{" "}
                          <Subtitle2
                            style={{
                              color: tokens.colorPaletteCranberryBorderActive,
                            }}
                          >
                            *
                          </Subtitle2>{" "}
                        </>
                      ) : null}
                    </Label>
                    {!metadata[key].editable || editScene[key] ? (
                      <>
                        <Image
                          width={150}
                          src={getImageSource(editScene[key]) || undefined}
                        />
                        <ChooseLibraryImg
                          onChosenImg={(img: string) =>
                            handleCustomChange(key, img)
                          }
                          btnTitle={"Change Image"}
                        />
                      </>
                    ) : (
                      <>
                        <ChooseLibraryImg
                          onChosenImg={(img: string) =>
                            handleCustomChange(key, img)
                          }
                        />
                        {errors[key] ? (
                          <ErrorMsg
                            message={errors[key] || ""}
                            withIcon={true}
                          />
                        ) : null}
                      </>
                    )}
                  </FormInputContainer>
                ) : null;
              })}
              <ScenePreview text="Preview:" currScene={currScene} />
              <ScenePreview
                text="Only No Depends :"
                currScene={currScene}
                onlyNoDepends
              />
            </FlexRowWrapped>
          </>
        ) : null}
      </FormContainer>

      <FlexColumn style={{ margin: "30px 0 40px" }}>
        <Divider />
      </FlexColumn>

      <ItemsTable isEdit={isEdit} sceneId={scene?._id} />

      {writeAndStronger ? (
        <Button
          className={buttonClasses.primary}
          appearance="primary"
          style={{ width: "250px", margin: "20px 0" }}
          onClick={onEditClick}
        >
          Save
        </Button>
      ) : null}
    </FlexColumn>
  );
};

export default ScenePage;

const ScenePreview = ({
  currScene,
  onlyNoDepends = false,
  text,
}: {
  currScene: Scene | null;
  onlyNoDepends?: boolean;
  text: string;
}) => {
  const sceneItems = useAppSelector((state) => state.editScene.sceneItems);

  return (
    <FlexColumn>
      {text}
      <FlexRow
        style={{
          position: "relative",
          border: "1px solid gray",
          width: "250px",
        }}
      >
        {currScene && currScene?.imageBackgroundPath ? (
          <Image
            width={250}
            src={getImageSource(currScene?.imageBackgroundPath) || undefined}
          />
        ) : null}
        {sceneItems.map((item) => {
          if (!onlyNoDepends || item.dependsOn === -1)
            return (
              <Image
                key={item._id}
                width={250}
                style={{ position: "absolute", top: 0, left: 0 }}
                src={getImageSource(item.imagePath) || undefined}
              />
            );
        })}
      </FlexRow>
    </FlexColumn>
  );
};

import { IGetAccountParams } from "../../../interfaces/api/accounts";
import { PayloadAction } from "@reduxjs/toolkit";
import { call, put, takeLatest } from "redux-saga/effects";
import { accountsApi } from "../../api/accounts-api";
import {
  getAccountFailed,
  getAccountStart,
  getAccountSuccess,
} from "./edit-account-slice";
import { alertError } from "../notifications-manager/notification-manager";

function* getAccountById(action: PayloadAction<IGetAccountParams>): any {
  try {
    const { id } = action.payload;
    const { data, error } = yield call(accountsApi.getAccountById, { id });
    if (error) {
      throw error;
    }
    yield put(getAccountSuccess(data));
  } catch (error: any) {
    yield put(getAccountFailed(error));
    yield put(alertError(error.message));
  }
}

function* editAccountSaga() {
  yield takeLatest(getAccountStart.type, getAccountById);
}

export default editAccountSaga;

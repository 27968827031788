import AuthUserSlice from "./auth-user/auth-user-slice";
import AccountsSlice from "./accounts/accounts-slice";
import EditAccountSlice from "./edit-account/edit-account-slice";
import NotificationsManagerSlice from "./notifications-manager/notification-manager";
import EditLibraryImageSlice from "./edit-library-image/edit-library-image-slice";
import LibrarySlice from "./images-library/library-slice";
import ScenesSlice from "./scenes/scenes-slice";
import EditSceneSlice from "./edit-scene/edit-scene-slice";
import EditSceneItemSlice from "./edit-scene-item/edit-scene-item-slice";
import BoxesSlice from "./boxes/boxes-slice";
import EditBoxSlice from "./edit-box/edit-box-slice";
import EditBoxPrizeSlice from "./edit-box-prize/edit-box-prize-slice";
import LevelsSlice from "./levels/levels-slice";
import EditLevelSlice from "./edit-level/edit-level-slice";
import DailyRewardsSlice from "./daily-rewards/daily-rewards-slice";
import EditDailyRewardSlice from "./edit-daily-reward/edit-daily-reward-slice";
import GameConfigSlice from "./game-config/game-config-slice";
import FotaSlice from "./fota/fota-slice";

const reducers = {
  auth: AuthUserSlice,
  accounts: AccountsSlice,
  editAccount: EditAccountSlice,
  notificationManager: NotificationsManagerSlice,
  editLibraryImage: EditLibraryImageSlice,
  library: LibrarySlice,
  scenes: ScenesSlice,
  editScene: EditSceneSlice,
  editSceneItem: EditSceneItemSlice,
  boxes: BoxesSlice,
  editBox: EditBoxSlice,
  editBoxPrize: EditBoxPrizeSlice,
  levels: LevelsSlice,
  editLevel: EditLevelSlice,
  dailyRewards: DailyRewardsSlice,
  editDailyReward: EditDailyRewardSlice,
  gameConfig: GameConfigSlice,
  fota: FotaSlice,
};

export default reducers;

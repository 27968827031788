import { styled } from "@mui/material";

import { Calendar } from "react-multi-date-picker";

export const CustomCalendar = styled(Calendar)(() => ({
  ".rmdp-header": {
    fontSize: "1.4rem",
  },
  ".rmdp-day.rmdp-selected span": {
    backgroundColor: "#33BCB0",
  },
  ".rmdp-arrow-container": {
    ":hover": {
      backgroundColor: "#33BCB0",
    },
  },
  ".rmdp-arrow": {
    border: "solid #045A6B",
    borderWidth: "0 2px 2px 0",
  },
  ".rmdp-week-day": {
    color: "#045A6B",
    fontSize: "1rem",
  },
  ".rmdp-range": {
    backgroundColor: "#33BCB0 !important",
    color: "#000000",
  },
  ".rmdp-day": {
    span: {
      ":hover": {
        backgroundColor: "unset",
        color: "unset",
      },
    },
    cursor: "default",
  },
  ".rmdp-day.rmdp-today span": {
    backgroundColor: "unset",
    color: "unset",
  },
}));

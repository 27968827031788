import { PayloadAction } from "@reduxjs/toolkit";
import { call, put, takeLatest } from "redux-saga/effects";
import {
  getGameConfigFailed,
  getGameConfigStart,
  getGameConfigSuccess,
  updateGameConfigFailed,
  updateGameConfigSuccess,
  updateGameConfigStart,
} from "./game-config-slice";
import gameConfigApi from "../../api/game-config-api";
import { alertError } from "../notifications-manager/notification-manager";
import { IUpdateGameConfigBody } from "../../../interfaces/api/game-config";

function* getGameConfig(): any {
  try {
    const { data, error } = yield call(gameConfigApi.getGameConfig);

    if (error) {
      yield put(getGameConfigFailed(error));
      return;
    }

    yield put(getGameConfigSuccess(data));
  } catch (error: any) {
    yield put(getGameConfigFailed(error));
  }
}

function* updateGameConfig(action: PayloadAction<IUpdateGameConfigBody>): any {
  try {
    const params = action.payload;

    const { data, error } = yield call(gameConfigApi.updateGameConfig, params);
    if (error) {
      yield put(updateGameConfigFailed(error));
      return;
    }
    yield put(updateGameConfigSuccess(data));
  } catch (error: any) {
    yield put(updateGameConfigFailed(error));
    yield put(alertError(error.message));
  }
}

function* gameConfigSaga() {
  yield takeLatest(getGameConfigStart.type, getGameConfig);
  yield takeLatest(updateGameConfigStart.type, updateGameConfig);
}

export default gameConfigSaga;

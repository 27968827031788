import { ROUTES_PATHS } from "../../../../common";
import FormInput from "../../../ui/inputs/FormInput";
import { ISubscription } from "../../../../interfaces/models";
import { useNavigate } from "react-router-dom";
import { ClickableDiv } from "./AppUserNav.style";
import { Link } from "@mui/icons-material";
import { accountsApi } from "../../../../store/api/accounts-api";
import { useAsync } from "../../../../hooks/useAsync";

export const AppUserNav = ({ value }: { value: any; row: ISubscription }) => {
  const navigate = useNavigate();

  const { value: account, status } = useAsync<any>(
    () => accountsApi.getAccountById({ id: value || "" }),
    [value]
  );

  return (
    <ClickableDiv
      exist={!!account?.data}
      onClick={() =>
        account?.data &&
        navigate(ROUTES_PATHS.Account.accountPage.replace(":id", value))
      }
    >
      <FormInput
        onClick={() =>
          account?.data &&
          navigate(ROUTES_PATHS.Account.accountPage.replace(":id", value))
        }
        value={value || ""}
        contentAfter={account?.data ? <Link /> : undefined}
        type="url"
        style={{
          marginTop: "5px",
          pointerEvents: "none",
          width: "100%",
          color: "blue",
        }}
      />
    </ClickableDiv>
  );
};

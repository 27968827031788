//Style
import "react-multi-date-picker/styles/layouts/mobile.css";
import { CustomCalendar } from "./SessionRangePopup.style";
import { FlexColumnCentered } from "../../../common/containers";

import { forwardRef, memo, useMemo } from "react";

//Styled Components
import {
  Slide,
  Dialog,
  IconButton,
  DialogTitle,
  DialogContent,
} from "@mui/material";
import { CloseRounded } from "@mui/icons-material";
import { TransitionProps } from "@mui/material/transitions";

//Hooks
import { useAsync } from "../../../../hooks/useAsync";

//Service
import { breathingCyclesApi } from "../../../../store/api";

//Utils
import { addDays, eachDayOfInterval } from "date-fns";

//Interfaces
import { DateObject } from "react-multi-date-picker";

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const SessionRangesPopup = memo(
  ({
    open,
    userId,
    onClose,
  }: {
    open?: boolean;
    onClose: () => void;
    userId: string;
  }) => {
    const { value: breathingCyclesStreak } = useAsync<string[]>(() =>
      breathingCyclesApi.getUserBreathingCycleStreak({ userId })
    );

    const dates7DaysStreak = useMemo(
      () =>
        breathingCyclesStreak?.length
          ? streak(breathingCyclesStreak?.map((date) => new Date(date)))
          : [],
      [breathingCyclesStreak]
    );

    const datesSet = new Set(breathingCyclesStreak || []);
    const streakSet = new Set(
      dates7DaysStreak
        .map((dates) =>
          eachDayOfInterval({ start: dates[0], end: addDays(dates[1], 1) })
        )
        .flat()
        .map((date) => date.toISOString().split("T")[0])
    );

    return (
      <Dialog
        open={!!open}
        fullWidth
        TransitionComponent={Transition}
        onClose={onClose}
      >
        <DialogTitle>Breathing Cycles Streak</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={() => ({
            position: "absolute",
            right: 8,
            top: 8,
          })}
        >
          <CloseRounded />
        </IconButton>
        <DialogContent>
          <FlexColumnCentered>
            <CustomCalendar
              value={dates7DaysStreak}
              currentDate={new DateObject()}
              mapDays={({ date }) => {
                const stringDate = new Date(
                  date.year,
                  date.month.index,
                  date.day + 1
                )
                  .toISOString()
                  .split("T")[0];
                return datesSet.has(stringDate)
                  ? {
                      style: {
                        border: "0.2rem solid #045A6B",
                        ...(!streakSet.has(stringDate) && {
                          backgroundColor: "#D8F3E5",
                        }),
                      },
                    }
                  : {};
              }}
              weekStartDayIndex={1}
              multiple
              range
              readOnly
              className="rmdp-mobile"
            />
          </FlexColumnCentered>
        </DialogContent>
      </Dialog>
    );
  }
);

const streak = (dates: Date[]) => {
  return dates
    .reduce((prev, date) => {
      const currentStreak = prev[prev.length - 1];
      if (
        //new array
        !currentStreak ||
        //7 days streak
        (currentStreak.length === 2 &&
          isDateDiff(currentStreak[0], currentStreak[1], -6)) ||
        //not sequence
        !isDateDiff(currentStreak[1] || currentStreak[0], date, -1)
      ) {
        // Start a new streak
        prev.push([date]);
      } else {
        const [start] = currentStreak;
        prev[prev.length - 1] = [start, date];
      }

      return prev;
    }, [] as Date[][])
    .filter(
      (streak) => streak.length === 2 && isDateDiff(streak[0], streak[1], -6)
    ); // Only keep completed streaks
};

const isDateDiff = (date1: Date, date2: Date, diff: number) => {
  return (
    (new Date(date1).getTime() - new Date(date2).getTime()) /
      (24 * 60 * 60 * 1000) ===
    diff
  );
};

import FormInput from "../../../ui/inputs/FormInput";
import { ISubscription } from "../../../../interfaces/models";
import { ErrorTwoTone } from "@mui/icons-material";
import { dateFormat, isSmallerThan } from "../../../../utils";
import { addDays } from "date-fns";
import { Tooltip } from "@mui/material";

export const SubscriptionDate = ({
  value,
}: {
  value: any;
  row: ISubscription;
}) => {
  const error = isSmallerThan(new Date(value), new Date());
  const warning = isSmallerThan(new Date(value), addDays(new Date(), 30));

  return (
    <Tooltip
      autoFocus={error || warning}
      title={error ? "expired" : warning ? "about to expire" : undefined}
      placement="bottom-end"
    >
      <FormInput
        value={value ? dateFormat(new Date(value), { withTime: false }) : ""}
        contentAfter={
          error || warning ? (
            <ErrorTwoTone
              color={error ? "error" : warning ? "warning" : "inherit"}
            />
          ) : undefined
        }
        style={{
          marginTop: "5px",
          pointerEvents: "none",
          width: "100%",
        }}
      />
    </Tooltip>
  );
};

const dev = {
  env: "dev",
  apiGateway: {
    API_SERVER_URL: "https://dev-cmsapi.calm2go.com",
    IMAGE_STORAGE_URL:
      "https://storage.googleapis.com/download/storage/v1/b/dev_calmigo_files/o",
    FILES_STORAGE_URL: "https://storage.googleapis.com/dev_calmigo_files/",
    DISTRIBUTION_STORAGE_URL: "",
  },
};

const int = {
  env: "int",
  apiGateway: {
    API_SERVER_URL: "https://dev-cmsapi.calm2go.com",
    IMAGE_STORAGE_URL:
      "https://storage.googleapis.com/download/storage/v1/b/dev_calmigo_files/o",
    FILES_STORAGE_URL: "https://storage.googleapis.com/dev_calmigo_files/",
    DISTRIBUTION_STORAGE_URL: "",
    NODE_TLS_REJECT_UNAUTHORIZED: 0,
  },
};

const prod = {
  env: "prod",
  apiGateway: {
    API_SERVER_URL: "https://cms-api.kwell.life",
    IMAGE_STORAGE_URL:
      "https://storage.googleapis.com/download/storage/v1/b/dev_calmigo_files/o",
    FILES_STORAGE_URL: "https://storage.googleapis.com/dev_calmigo_files/",
    DISTRIBUTION_STORAGE_URL: "",
  },
};

let config = dev;
if (
  process.env.REACT_APP_STAGE &&
  process.env.REACT_APP_STAGE.trim() === "production"
) {
  config = prod;
} else if (
  process.env.REACT_APP_STAGE &&
  process.env.REACT_APP_STAGE.trim() === "int"
) {
  config = int;
  console.log(
    `using env int. ${process.env.REACT_APP_NODE_TLS_REJECT_UNAUTHORIZED}`
  );
}

console.log("ENV:", process.env.REACT_APP_STAGE);

let exportConfig = {
  APP_VERSION: 0.1,
  ...config,
};
export default exportConfig;
